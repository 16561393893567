import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, computed } from 'vue';
import { useReservedPageStore } from '@/stores/reservedPageStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReserveList',
  setup(__props) {

const tab = ref(1);
const reservedPage = useReservedPageStore();

// serverItems や sortBy、ヘッダーなどは reservedPage 内に状態として持つ前提
const calendarReserver = computed(() =>
  reservedPage.serverItems.filter((item: any) => item.paymentOnly === false)
);
const paymentOnlyReserver = computed(() =>
  reservedPage.serverItems.filter((item: any) => item.paymentOnly === true)
);

return (_ctx: any,_cache: any) => {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_tabs_window_item = _resolveComponent("v-tabs-window-item")!
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_tabs, {
      modelValue: tab.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tab).value = $event)),
      "align-tabs": "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab, { value: "1" }, {
          default: _withCtx(() => _cache[21] || (_cache[21] = [
            _createTextVNode("予約一覧")
          ])),
          _: 1
        }),
        _createVNode(_component_v_tab, { value: "2" }, {
          default: _withCtx(() => _cache[22] || (_cache[22] = [
            _createTextVNode("支払いのみ一覧")
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_tabs_window, {
      modelValue: tab.value,
      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((tab).value = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tabs_window_item, { value: "1" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_data_table, {
              items: calendarReserver.value,
              headers: _unref(reservedPage).reserveHeaders,
              "sort-by": _unref(reservedPage).sortBy,
              "onUpdate:sortBy": _cache[10] || (_cache[10] = ($event: any) => ((_unref(reservedPage).sortBy) = $event))
            }, {
              top: _withCtx(() => [
                _createVNode(_component_v_toolbar, { flat: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_toolbar_title, null, {
                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                        _createTextVNode("予約一覧")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_divider, {
                      class: "mx-4",
                      inset: "",
                      vertical: ""
                    }),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_toolbar_items, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_dialog, {
                          modelValue: _unref(reservedPage).show,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(reservedPage).show) = $event)),
                          width: "500px"
                        }, {
                          activator: _withCtx(({ on, attrs }) => [
                            _createVNode(_component_v_btn, _mergeProps({ icon: "" }, attrs, _toHandlers(on), {
                              onClick: _unref(reservedPage).toggleHelp
                            }), {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[24] || (_cache[24] = [
                                    _createTextVNode("mdi-help-circle-outline")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1040, ["onClick"])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_card, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    _cache[27] || (_cache[27] = _createTextVNode(" 予約が完了した人の一覧です。予約編集の欄の ")),
                                    _createVNode(_component_v_icon, { small: "" }, {
                                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                                        _createTextVNode("mdi-pencil")
                                      ])),
                                      _: 1
                                    }),
                                    _cache[28] || (_cache[28] = _createTextVNode(" で各種編集やZOOM URLの確認、 ")),
                                    _createVNode(_component_v_icon, { small: "" }, {
                                      default: _withCtx(() => _cache[26] || (_cache[26] = [
                                        _createTextVNode("mdi-delete")
                                      ])),
                                      _: 1
                                    }),
                                    _cache[29] || (_cache[29] = _createTextVNode(" で予約のキャンセルが出来ます。ただし料金の返金は出来ませんので、別途ご対応をお願いします。 "))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_dialog, {
                      modelValue: _unref(reservedPage).dialog,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(reservedPage).dialog) = $event)),
                      "max-width": "500px"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_container, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _unref(reservedPage).editedItem.name,
                                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(reservedPage).editedItem.name) = $event)),
                                              label: "お名前"
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _unref(reservedPage).editedItem.email,
                                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(reservedPage).editedItem.email) = $event)),
                                              label: "メールアドレス"
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _unref(reservedPage).editedItem.title,
                                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(reservedPage).editedItem.title) = $event)),
                                              label: "プラン名(変更不可)",
                                              readonly: ""
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _unref(reservedPage).editedItem.orderDate,
                                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(reservedPage).editedItem.orderDate) = $event)),
                                              label: "予約日(変更不可)",
                                              readonly: ""
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _unref(reservedPage).editedItem.zoomUrl,
                                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(reservedPage).editedItem.zoomUrl) = $event)),
                                              label: "ZOOM URL(変更不可)",
                                              readonly: ""
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _unref(reservedPage).editedItem.paymentId,
                                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(reservedPage).editedItem.paymentId) = $event)),
                                              label: "支払いID(変更不可)",
                                              readonly: ""
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_card_actions, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_spacer),
                                _createVNode(_component_v_btn, {
                                  color: "blue darken-1",
                                  onClick: _unref(reservedPage).close
                                }, {
                                  default: _withCtx(() => _cache[30] || (_cache[30] = [
                                    _createTextVNode("キャンセル")
                                  ])),
                                  _: 1
                                }, 8, ["onClick"]),
                                _createVNode(_component_v_btn, {
                                  color: "blue darken-1",
                                  onClick: _unref(reservedPage).save
                                }, {
                                  default: _withCtx(() => _cache[31] || (_cache[31] = [
                                    _createTextVNode("保存する")
                                  ])),
                                  _: 1
                                }, 8, ["onClick"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createVNode(_component_v_dialog, {
                      modelValue: _unref(reservedPage).dialogDelete,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(reservedPage).dialogDelete) = $event)),
                      "max-width": "500px"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, { class: "text-h5" }, {
                              default: _withCtx(() => _cache[32] || (_cache[32] = [
                                _createTextVNode("この予約をキャンセルしてよろしいですか?")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_card_actions, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_spacer),
                                _createVNode(_component_v_btn, {
                                  color: "blue darken-1",
                                  onClick: _unref(reservedPage).deleteItemConfirm
                                }, {
                                  default: _withCtx(() => _cache[33] || (_cache[33] = [
                                    _createTextVNode("はい")
                                  ])),
                                  _: 1
                                }, 8, ["onClick"]),
                                _createVNode(_component_v_btn, {
                                  color: "blue darken-1",
                                  onClick: _unref(reservedPage).closeDelete
                                }, {
                                  default: _withCtx(() => _cache[34] || (_cache[34] = [
                                    _createTextVNode("いいえ")
                                  ])),
                                  _: 1
                                }, 8, ["onClick"]),
                                _createVNode(_component_v_spacer)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              "item.actions": _withCtx(({ item }) => [
                _createVNode(_component_v_icon, {
                  small: "",
                  class: "mr-2",
                  onClick: ($event: any) => (_unref(reservedPage).editItem(item))
                }, {
                  default: _withCtx(() => _cache[35] || (_cache[35] = [
                    _createTextVNode(" mdi-pencil ")
                  ])),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_v_icon, {
                  small: "",
                  onClick: ($event: any) => (_unref(reservedPage).deleteItem(item))
                }, {
                  default: _withCtx(() => _cache[36] || (_cache[36] = [
                    _createTextVNode(" mdi-delete ")
                  ])),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            }, 8, ["items", "headers", "sort-by"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_tabs_window_item, { value: "2" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_data_table, {
              items: paymentOnlyReserver.value,
              headers: _unref(reservedPage).paymentOnlyHeaders,
              "sort-by": _unref(reservedPage).sortBy,
              "onUpdate:sortBy": _cache[19] || (_cache[19] = ($event: any) => ((_unref(reservedPage).sortBy) = $event))
            }, {
              top: _withCtx(() => [
                _createVNode(_component_v_toolbar, { flat: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_toolbar_title, null, {
                      default: _withCtx(() => _cache[37] || (_cache[37] = [
                        _createTextVNode("支払いのみ一覧")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_divider, {
                      class: "mx-4",
                      inset: "",
                      vertical: ""
                    }),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_toolbar_items, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_dialog, {
                          modelValue: _unref(reservedPage).showPaymentOnly,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(reservedPage).showPaymentOnly) = $event)),
                          width: "500px"
                        }, {
                          activator: _withCtx(({ on, attrs }) => [
                            _createVNode(_component_v_btn, _mergeProps({ icon: "" }, attrs, _toHandlers(on), {
                              onClick: _unref(reservedPage).togglePaymentOnlyHelp
                            }), {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[38] || (_cache[38] = [
                                    _createTextVNode("mdi-help-circle-outline")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1040, ["onClick"])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_card, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    _cache[40] || (_cache[40] = _createTextVNode(" 日時やZOOMの予約のない、支払いのみの人の一覧です。顧客情報編集の欄の ")),
                                    _createVNode(_component_v_icon, { small: "" }, {
                                      default: _withCtx(() => _cache[39] || (_cache[39] = [
                                        _createTextVNode("mdi-pencil")
                                      ])),
                                      _: 1
                                    }),
                                    _cache[41] || (_cache[41] = _createTextVNode(" で顧客の詳細を確認出来ます。 "))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_dialog, {
                      modelValue: _unref(reservedPage).dialogPaymentOnly,
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_unref(reservedPage).dialogPaymentOnly) = $event)),
                      "max-width": "500px"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_text, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_container, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _unref(reservedPage).editedItem.name,
                                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(reservedPage).editedItem.name) = $event)),
                                              label: "お名前"
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _unref(reservedPage).editedItem.email,
                                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(reservedPage).editedItem.email) = $event)),
                                              label: "メールアドレス"
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _unref(reservedPage).editedItem.title,
                                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(reservedPage).editedItem.title) = $event)),
                                              label: "プラン名(変更不可)",
                                              readonly: ""
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _unref(reservedPage).editedItem.orderDate,
                                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(reservedPage).editedItem.orderDate) = $event)),
                                              label: "支払い日(変更不可)",
                                              readonly: ""
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              modelValue: _unref(reservedPage).editedItem.paymentId,
                                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(reservedPage).editedItem.paymentId) = $event)),
                                              label: "支払いID(変更不可)",
                                              readonly: ""
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_card_actions, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_spacer),
                                _createVNode(_component_v_btn, {
                                  color: "blue darken-1",
                                  onClick: _unref(reservedPage).close
                                }, {
                                  default: _withCtx(() => _cache[42] || (_cache[42] = [
                                    _createTextVNode("キャンセル")
                                  ])),
                                  _: 1
                                }, 8, ["onClick"]),
                                _createVNode(_component_v_btn, {
                                  color: "blue darken-1",
                                  onClick: _unref(reservedPage).save
                                }, {
                                  default: _withCtx(() => _cache[43] || (_cache[43] = [
                                    _createTextVNode("保存する")
                                  ])),
                                  _: 1
                                }, 8, ["onClick"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createVNode(_component_v_dialog, {
                      modelValue: _unref(reservedPage).dialogPaymentOnlyDelete,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_unref(reservedPage).dialogPaymentOnlyDelete) = $event)),
                      "max-width": "500px"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, { class: "text-h5" }, {
                              default: _withCtx(() => _cache[44] || (_cache[44] = [
                                _createTextVNode(" この支払いを削除してよろしいですか?"),
                                _createElementVNode("br", null, null, -1),
                                _createTextVNode("※この処理は戻せません ")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_v_card_actions, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_spacer),
                                _createVNode(_component_v_btn, {
                                  color: "blue darken-1",
                                  onClick: _unref(reservedPage).deleteItemConfirmPaymentOnly
                                }, {
                                  default: _withCtx(() => _cache[45] || (_cache[45] = [
                                    _createTextVNode("はい")
                                  ])),
                                  _: 1
                                }, 8, ["onClick"]),
                                _createVNode(_component_v_btn, {
                                  color: "blue darken-1",
                                  onClick: _unref(reservedPage).closeDelete
                                }, {
                                  default: _withCtx(() => _cache[46] || (_cache[46] = [
                                    _createTextVNode("いいえ")
                                  ])),
                                  _: 1
                                }, 8, ["onClick"]),
                                _createVNode(_component_v_spacer)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              "item.actions": _withCtx(({ item }) => [
                _createVNode(_component_v_icon, {
                  small: "",
                  class: "mr-2",
                  onClick: ($event: any) => (_unref(reservedPage).editItem(item))
                }, {
                  default: _withCtx(() => _cache[47] || (_cache[47] = [
                    _createTextVNode(" mdi-pencil ")
                  ])),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            }, 8, ["items", "headers", "sort-by"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})