import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/authStore'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute()
const router = useRouter()
const drawer = ref(false)
const auth = useAuthStore()

const dashboardPageList = [
  { title: 'ダッシュボードトップ', to: '/Dashboard' },
  { title: '各種設定',       to: '/Settings' },
  { title: '予約一覧',       to: '/ReserveList' },
  { title: 'プラン一覧',     to: '/PlanList' },
  { title: '予約日追加',     to: '/AddReserveCalendar' },
]

return (_ctx: any,_cache: any) => {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      (_unref(route).meta.dashboard)
        ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
            key: 0,
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (drawer.value = !drawer.value), ["stop"]))
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          (_unref(route).meta.dashboard)
            ? (_openBlock(), _createBlock(_component_v_navigation_drawer, {
                key: 0,
                modelValue: drawer.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((drawer).value = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(dashboardPageList, (item) => {
                        return _createVNode(_component_v_list_item, {
                          key: item.to,
                          onClick: ($event: any) => (_unref(router).push(item.to)),
                          disabled: !_unref(auth).loggedIn
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.title), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick", "disabled"])
                      }), 64))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(auth).logout(_unref(router))))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode("ログアウト")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})