import { reactive } from 'vue'
import { data } from './useData'
import { firebase } from './useFirebase'
import { cloudFunctions } from './useCloudFunctions'
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import { loadConnectAndInitialize } from '@stripe/connect-js';

export const method = reactive({
    async login(mail:string,password:string){
        data.login.button = true;
        const userCredential = await firebase.userLogin(mail,password)
        if(userCredential){
            data.login.check = true;
            data.loginUser.email = '';
            data.loginUser.password = '';
            data.loginUser.uid = userCredential.user.uid;
            console.log('userCredential',userCredential)
            const userData = await firebase.firestore.load.userData();
            console.log('userData',userData)
            if(userData){
                data.loginUser.name = userData.name;
                data.loginUser.email = userData.email;
                data.loginUser.reservePlans = userData.plans;
            }
            const plans = await firebase.firestore.load.plans();
            if(plans){
                data.loginUser.reservePlans = plans;
            }
            const events = await firebase.firestore.load.event.editor();
            const settingData = await firebase.firestore.load.settingData();
            await firebase.firestore.load.order();
            console.log('events',events,'plans',plans,'settingData',settingData)
            if(events){
                data.dashboardCalendar.events = events;
            }
            return true;
        }
        return false;
    },
    async logout(router:any){
        const confirm = window.confirm('ログアウトしますか？')
        if(!confirm) return;
        const result = await firebase.userLogout()
        if(result){
            data.loginUser.uid = '';
            data.login.check = false;
            data.login.button = false;
            router.push('./dashboard')
        } else {
            alert('ログアウトに失敗しました')
        }
    },
    async resetPassword(){
        await firebase.resetPassword();
        data.loginUser.resetPassword = false;
    },
    setEvents(){
        console.log('setEvents')
        data.dashboardCalendar.events = data.dashboardCalendar.events.map((event:any) => {
            event.textColor = event.plan.color;
            return event;
        });
    },
    calendar:{
        async eventLoad(){
            console.log('eventLoad',data.reserveUser.uid)
            if(!data.reserveUser.uid){
                alert('アクセス出来ません')
                return;
            }
            const events = await firebase.firestore.load.event.reserver();
            await firebase.firestore.load.order();
            if(events){
                const filterEvents = events.filter(event => {
                    return !this.isEventOverlapping(event, data.orderFinishUsers);
                  });
                data.calendar.events = filterEvents;           
            }
        },
        isEventOverlapping(event:CalendarEvent, orders:OrderFinishUser[]) {
            console.log('isEventOverlapping',event,orders)
            if (event.orderId === '') {
                return orders.some(order => {
                  return (event.startSeconds < order.end.seconds && event.endSeconds > order.start.seconds);
                });
              } else {
                // event.emailが空でない場合は重複なしとみなす
                return false;
              }
          },
        eventClick({event}:any){
            console.log('calendar event',event,data.calendar.selectedEventDetails)
            if(data.calendar.selectedEventDetails.length >= 1){                
                data.calendar.selectedEventDetails = []
            }
            console.log('data.calendar.selectedEventDetails',data.calendar.selectedEventDetails)
            const { startHour,startMinute,endHour,endMinute,month,day,eventDay } = data.dashboardCalendar.exchangeTimes(event);
            console.log('startHour',startHour,startMinute,endHour,endMinute,month,day,eventDay)
            data.calendar.showModal = true
            const selectEvent:any = {
                month:event.extendedProps.month || month,
                day:event.extendedProps.day || day,
                title: event.title || '',
                start: event.start,
                end: event.end,
                description: event.description || '',
                plan: event.extendedProps.plan || [],
                id: event.id,
                eventDay:event.extendedProps.eventDay,
                startHour:event.extendedProps.startHour || startHour,
                startMinute:event.extendedProps.startMinute || startMinute,
                endHour:event.extendedProps.endHour || endHour,
                endMinute:event.extendedProps.endMinute || endMinute,
                selectedPlan: event.extendedProps.selectedPlan,          
            };
            data.calendar.selectedEventDetails.push(selectEvent);
        },
    },
    dashboardCalendar:{
        exchangeTimes(event:any){
            console.log('exchangeTimes',event)
               const start = new Date(event.start);
               const end = new Date(event.end);
                const startHour = start.getHours().toString().padStart(2, '0');
                const startMinute = start.getMinutes().toString().padStart(2, '0');
                const endHour = end.getHours().toString().padStart(2, '0');
                const endMinute = end.getMinutes().toString().padStart(2, '0');
                const month = start.getMonth()+1;
                const day = start.getDate();
                const eventDay = start
                return { startHour, startMinute, endHour, endMinute,month,day,eventDay };
        },
        exchangeTimesReverse(events = data.dashboardCalendar.selectedEventDetails,date=null) {
            console.log('exchangeTimesReverse',events,date)
            
            const exchanges = events.map((event:any) => {
                let { startHour, startMinute, endHour, endMinute,month,day } = event;
                if(date){
                    month = dayjs(date).month()+1;
                    day = dayjs(date).date();
                }
                console.log('exchangeTimesReverse',startHour, startMinute, endHour, endMinute,month,day)
                // 現在の日付を基準とします（特定の日付を使用する場合は、この部分を調整してください）
                // monthとdayから日付を取得する
                const eventDay = dayjs().month(month-1).date(day).format('YYYY-MM-DD');
                console.log('eventDay',eventDay)
                const currentDate = new Date(eventDay);
                const year = currentDate.getFullYear();
                // const month = currentDate.getMonth() +1;
                // const day = currentDate.getDate();

                // 開始時刻と終了時刻のDateオブジェクトを作成
                const startDateTime = new Date(year, month-1, day, startHour, startMinute);
                const endDateTime = new Date(year, month-1, day, endHour, endMinute);
                return { startDateTime, endDateTime, month, day };
            });
            console.log('exchanges',exchanges)
            
            return exchanges;

        },
        pushSelectedEvents(events:any){
            console.log('pushSelectedEvents',events)
            if(data.dashboardCalendar.selectedEventDetails.length >= 1){
                data.dashboardCalendar.selectedEventDetails = []
            }
            events.forEach((event:any) => {
                const { startHour,startMinute,endHour,endMinute,month,day,eventDay } = data.dashboardCalendar.exchangeTimes(event);
                if(event.extendedProps?.plan?.length === 0){
                    console.log('plan初期値')
                    event.plan = cloneDeep(data.loginUser.reservePlans)
                }else if(event.extendedProps?.plan?.length >= 1){
                    console.log('イベントのプラン')
                    event.plan = event.extendedProps.plan
                }
                // console.log('checkedPlans',event.plan)
                data.dashboardCalendar.selectedEventDetails.push({
                    month,
                    day,
                    title: event.title || '',
                    start: event.start,
                    end: event.end,
                    startStr:'',
                    startHour,
                    startMinute,
                    endHour,
                    endMinute,
                    description: event.extendedProps?.description || '',
                    plan: event.plan,
                    id: event.id,
                    eventDay,
                    selectedPlan: event.extendedProps?.selectedPlan,
                });
            });
        },
        eventClick({event}:any){
            console.log('event',event)
            const events = [event]
            if(data.dashboardCalendar.selectedEventDetails.length >= 1){
                data.dashboardCalendar.selectedEventDetails = []
            }
            data.dashboardCalendar.showModal = true
            data.dashboardCalendar.pushSelectedEvents(events);
        },
        dateClick({dateStr}:any){
            console.log('dateStr',dateStr)
            if(data.loginUser.reservePlans.length === 0){
                alert('プランを登録してください')
                return
            }
            if(data.dashboardCalendar.selectedEventDetails.length >= 1){
                data.dashboardCalendar.selectedEventDetails = []
            }
            const [month, day] = dateStr.split('-').slice(1);
            console.log('dateClick',month,day)
            data.dashboardCalendar.addReserveModal = true
            console.log('dateClick',data.loginUser.reservePlans)
            data.dashboardCalendar.selectedEventDetails.push({
                month,
                day,
                title: '',
                description: '',
                plan: cloneDeep(data.loginUser.reservePlans),
                startHour: 0,
                startMinute: 0,
                endHour: 0,
                endMinute: 0,
                eventId: '',
                eventDay: dateStr,
                start:'',
                end:'',
                startStr:'',
                selectedPlan:{},
            })
        },
        eventDidMount(info:any) {
            info.el.addEventListener('contextmenu', (e:any) => {
                console.log('eventDidMount', info);
                e.stopPropagation();
                const events = [info.event];
                data.dashboardCalendar.pushSelectedEvents(events);
                data.dashboardCalendar.menuItems = [
                    { text: 'コピー', action: 'copy' },
                    { text: '削除', action: 'delete' }
                ];
                
                e.preventDefault();
        
                // ページ全体のスクロール位置を考慮してメニューの表示位置を計算
                const scrollX = window.pageXOffset || document.documentElement.scrollLeft;
                const scrollY = window.pageYOffset || document.documentElement.scrollTop;
        
                // メニューが画面外に出ないように調整
                const menuX = e.clientX + scrollX;
                const menuY = e.clientY + scrollY;
                const windowHeight = window.innerHeight;
                const windowWidth = window.innerWidth;
                const menuHeight = 100;  // メニューの高さ（実際の高さに調整）
                const menuWidth = 150;   // メニューの幅（実際の幅に調整）
        
                let adjustedMenuY = menuY;
                let adjustedMenuX = menuX;
        
                if (menuY + menuHeight > windowHeight + scrollY) {
                    adjustedMenuY = windowHeight + scrollY - menuHeight;
                }
        
                if (menuX + menuWidth > windowWidth + scrollX) {
                    adjustedMenuX = windowWidth + scrollX - menuWidth;
                }
        
                data.dashboardCalendar.menuX = adjustedMenuX;
                data.dashboardCalendar.menuY = adjustedMenuY;
                data.dashboardCalendar.menu = true;
                console.log('eventDidMount1', data.dashboardCalendar);
            });
        },
        dayCellDidMount(info:any){
            info.el.addEventListener('contextmenu', (e:any) => {
                console.log('dayCellDidMount',info)
                e.stopPropagation();
                if(info.date){
                    const eventDate = dayjs(info.date).format('YYYY-MM-DD');
                    const events = data.dashboardCalendar.events.filter((event:any) => {
                        return dayjs(event.start).format('YYYY-MM-DD') === eventDate;
                    });
                    console.log('events',events,eventDate)
                    data.dashboardCalendar.pushSelectedEvents(events);
                }
                data.dashboardCalendar.menuItems = [
                    {
                        text: '全てコピー',
                        action: 'allCopy',
                    },
                    {
                        text: '貼り付け',
                        action: 'paste',
                        date: info.date,
                    }
                ];
                e.preventDefault(); // デフォルトのコンテキストメニューを抑制
                data.dashboardCalendar.menuX = e.pageX;
                data.dashboardCalendar.menuY = e.pageY;
                data.dashboardCalendar.menu = true;
              });
        },
        menuAction(action:any,date:any){
            console.log('menuAction',action,date)
            if(action === 'copy'){
                console.log('this',this)
                this.event.copy();
            }else if(action === 'allCopy'){
                this.event.allCopy();
            }else if(action === 'paste'){
                this.event.paste(date);
            }else if(action === 'delete'){
                this.event.delete()
            };
            data.dashboardCalendar.menu = false;
        },
        event:{
            copy(){
                console.log('copy',data.dashboardCalendar.selectedEventDetails)
                if(data.dashboardCalendar.event.copyData.length >= 1){
                    data.dashboardCalendar.event.copyData = []
                }
                data.dashboardCalendar.event.copyData.push(...data.dashboardCalendar.selectedEventDetails);
                console.log('copyData',data.dashboardCalendar.event.copyData)
            },
            allCopy(){
                if(data.dashboardCalendar.event.copyData.length >= 1){
                    data.dashboardCalendar.event.copyData = []
                }
                data.dashboardCalendar.event.copyData.push(...data.dashboardCalendar.selectedEventDetails);
                console.log('copyData',data.dashboardCalendar.event.copyData)
            },
            paste(date:any){
                console.log('paste',date)
                method.dashboardCalendar.save.event.new(data.dashboardCalendar.event.copyData,date);
            },
            delete(){
                const targetEventId = data.dashboardCalendar.selectedEventDetails[0].id;
                const confirmResult = confirm('削除しますか？')
                if(confirmResult && targetEventId){
                    console.log('delete',targetEventId)
                    firebase.firestore.delete.event(targetEventId);
                    data.dashboardCalendar.events = data.dashboardCalendar.events.filter((event:any) => event.id !== targetEventId);
                }
            },
        },
        save:{
            event:{
                async new(setEvent = data.dashboardCalendar.selectedEventDetails,date=null){
                    // 時刻をチェックして、設定不可な時間になっていたらエラーを出すようにする（開始時間と終了時間が同じもダメ。）
                    setEvent.forEach(async (eventDetail:any) => {
                    const exchangeTimes = data.dashboardCalendar.exchangeTimesReverse([eventDetail],date);
                    const { startDateTime, endDateTime } = exchangeTimes[0];
                    console.log('new save',eventDetail,startDateTime,endDateTime)
                    
                    const docRef = await firebase.firestore.save.event.getNewDocID();//事前にドキュメントIDを取得して、フィールドに含める
                        data.dashboardCalendar.events.push({
                            uid:data.loginUser.uid,
                            id: docRef.id,
                            title:data.loginUser.reservePlans.find((plan:any) => plan.id === eventDetail.selectedPlan)?.title ?? 'タイトル無し',
                            start: startDateTime,
                            end: endDateTime,
                            description: eventDetail.description,
                            plan: eventDetail.plan,
                            selectedPlan: eventDetail.selectedPlan,
                            orderId: '',
                            name: '',
                            email: '',
                        });
                        console.log('new save events',data.dashboardCalendar.events)
                        const result = firebase.firestore.save.event.new(docRef,data.dashboardCalendar.events);
                    });
                    data.dashboardCalendar.addReserveModal = false;
                    data.dashboardCalendar.selectedEventDetails = [];
                },
                edit(){
                    data.dashboardCalendar.selectedEventDetails.forEach((event:any) => {
                        console.log('save event edit',event)
                        const exchangeTimes = data.dashboardCalendar.exchangeTimesReverse([event]);
                        const { startDateTime, endDateTime,month,day } = exchangeTimes[0];
                        event.start = startDateTime;
                        event.end = endDateTime;
                        event.month = month;
                        event.day = day;
                        const foundPlan = data.loginUser.reservePlans.find((plan: ReservePlan) => plan.id === event.selectedPlan);
                        const eventTitle = foundPlan ? foundPlan.title : "タイトル無し";
                        event.title = eventTitle;
                        event.selectedPlan = event.selectedPlan;
                        firebase.firestore.save.event.edit(event);
                        data.dashboardCalendar.events.forEach((editEvent:any) => {
                            console.log('editEvent',editEvent)
                            if(editEvent.id === event.id){
                                // const foundPlan = data.loginUser.reservePlans.find((plan: ReservePlan) => plan.id === event.selectedPlan);
                                // const eventTitle = foundPlan ? foundPlan.title : "タイトル無し";
                                console.log('foundPlan',foundPlan,'eventTitle',eventTitle)
                                editEvent.title = eventTitle;
                                editEvent.start = event.start;
                                editEvent.end = event.end;
                                editEvent.description = event.description;
                                editEvent.plan = event.plan;
                            }
                        })
                    });
                    console.log('edit',data.dashboardCalendar.selectedEventDetails)
                    data.dashboardCalendar.showModal = false;
                },
            },
        },
        async copyUrl(tooltip:number,url:string){
            await navigator.clipboard.writeText(url);
            if(tooltip === 1){
                data.dashboardCalendar.tooltip1 = true;
                setTimeout(() => {
                    data.dashboardCalendar.tooltip1 = false;
                }, 3000);
            }else if(tooltip === 2){
                data.dashboardCalendar.tooltip2 = true;
                setTimeout(() => {
                    data.dashboardCalendar.tooltip2 = false;
                }, 3000);
            }else if(tooltip === 3){
                data.dashboardCalendar.tooltip3 = true;
                setTimeout(() => {
                    data.dashboardCalendar.tooltip3 = false;
                }, 3000);
            }
        },
    },
    reserve:{
        async submit(router:any){
            console.log('tempSubmit',router)
            data.reserveUser.submit = true;
            if(data.calendar.selectedEventDetails.length > 1 ){
                alert('複数の予約は出来ません')
                return;
            }
            const reserveData = data.calendar.selectedEventDetails[0];
            if(!reserveData.id){return}
            const result = await this.addReserveTemp(reserveData.id)
            if(!result) return;
            router.push(`./reserve`);
            await this.submitSection1(router,reserveData)
        },
        async submitSection1(router:any,reserveData:any){
            try{
                const settingData = await firebase.firestore.load.settingData();
                if(settingData){
                    data.settingData = settingData;
                }
                console.log('settingData読み込み',data.settingData)
                if(data.settingData.replyMailAddress === ''){
                    alert('返信先メールアドレスが設定されていません')
                    await this.ejectReserveTemp(reserveData.id)
                    router.back();
                    data.calendar.showModal = false;
                    return;
                }
                const paymentPrice = !reserveData.plan ? reserveData.price : reserveData.plan.find((plan:any) => plan.id === reserveData.selectedPlan)?.price;
                console.log('paymentPrice',paymentPrice)
                if((data.settingData.stripe.use && paymentPrice !== 0) || (data.settingData.stores.use && paymentPrice !== 0)){ //クレジット決済する場合
                    console.log('クレジット決済')
                    if(!data.settingData.stripe.connectedAccountId){
                        alert('Stripeのアカウントが設定されていません。設定ページからStripeのアカウント設定をお願いします。')
                        method.reserve.eject(router,reserveData,'');
                        return;
                    }
                    const creditResult = await this.creditPayment(reserveData)
                    if(!creditResult){
                        alert('クレジット決済に失敗しました')
                        await this.ejectReserveTemp(reserveData.id)
                        router.back();
                        data.calendar.showModal = false;
                        return;
                    }
                } else {
                    await this.submitSection2(router,reserveData)
                }
            } catch(e:any){
                await method.reserve.eject(router,reserveData,e);
            }
        },
        async submitSection2(router:any,reserveData:any){
            console.log('submitSection2',reserveData)
            try{
                let zoomResult = null
            if(data.settingData.zoom && !reserveData.paymentOnly){
                zoomResult = await this.createZoomMeeting(reserveData)
                if(!zoomResult){
                    alert('zoomミーティングの作成に失敗しました')
                    method.reserve.eject(router,reserveData);
                    return;
                }
                console.log('zoomResult',zoomResult)
            }
            await this.addReserve(zoomResult,reserveData)
            this.sendEmail(zoomResult,reserveData)
            this.reserveComplete(reserveData);
                router.push('./ReserveComplete');
            }catch(e:any){
                await method.reserve.eject(router,reserveData,e);
            }
        },
        async creditPayment(reserveData:any){
            console.log('creditPayment',data.reserveUser)
            let result = true;
            if(!data.settingData.stripe.use && !data.settingData.stores.use){
                return result
            }else if(data.settingData.stripe.use && !data.settingData.stores.use) {
                console.log('クレジット支払い処理')
                const paymentData = {
                    successUrl: `${window.location.origin}/${data.reserveUser.uid}/reserve?session_id={CHECKOUT_SESSION_ID}`,
                    cancelUrl: `${window.location.origin}/${data.reserveUser.uid}`,
                    lineItems:[{
                        price_data: {
                            currency: 'jpy',
                            product_data: {
                                name: data.reserveUser.selectedPlan.title,
                            },
                            unit_amount: data.reserveUser.selectedPlan.price,
                        },
                        quantity: 1,
                    }],
                    email: data.reserveUser.email,
                    ammount: data.reserveUser.selectedPlan.price,
                    paymentMethodId: ['card'],
                    mode: 'payment',
                    customerId: data.reserveUser.uid,
                    description: '予約料金',
                    receiptEmail: data.reserveUser.email,
                    metadata: {
                      reserveId: data.reserveUser.reserveDate,
                    },
                    test: data.settingData.stripe.test,
                    connectedAccountId: data.settingData.stripe.connectedAccountId,
                    uid: data.reserveUser.uid,
                  };
                  console.log('paymentData',paymentData)
                  const sessionId:any = await cloudFunctions.stripePaymentV2(paymentData)
                  console.log('sessionId',sessionId)
                    localStorage.setItem('reserveData',JSON.stringify(data.calendar.selectedEventDetails[0] ? data.calendar.selectedEventDetails[0] : reserveData))
                    localStorage.setItem('reserveUser',JSON.stringify(data.reserveUser))
                    localStorage.setItem('settingData',JSON.stringify(data.settingData))
                  window.location.href = sessionId.data.url;
                  return true;
                //   return new Promise(() => {});
            }else if(!data.settingData.stripe.use && data.settingData.stores.use){
                console.log('stores支払い処理')
                const paymentData = {
                    amount : data.reserveUser.selectedPlan.price, 
                    currency: "jpy",
                    locale: "ja_JP",
                    successUrl: `${window.location.origin}${data.reserveUser.uid}/reserve?stores=stores`,
                    cancelUrl: `${window.location.origin}${data.reserveUser.uid}`,
                    //webhookUrl: "https://coiney.com/webhook",
                    method: "creditcard",
                    subject: "スタンダードプラン",
                    description: "ウェブサイトからの支払い",
                    remarks: "お支払い期日を過ぎますと自動的にキャンセルとなります。あらかじめご了承ください。",
                    // "metadata: {
                    // "orderId: '"'+order+'"'
                    // },
                    // expiredOn: expiredOn
                    uid: data.reserveUser.uid,
                  };
                  const paymentUrl:any = await cloudFunctions.storesPayment(paymentData);
                  console.log('paymentUrl',paymentUrl)
                  data.settingData.stores.id = paymentUrl.data.id;
                    localStorage.setItem('reserveData',JSON.stringify(data.calendar.selectedEventDetails[0] ? data.calendar.selectedEventDetails[0] : reserveData))
                    localStorage.setItem('reserveUser',JSON.stringify(data.reserveUser))
                    localStorage.setItem('settingData',JSON.stringify(data.settingData))
                  window.location.href = paymentUrl.data.links.paymentUrl;
                  return true;
            }
            
            // throw new Error('creditPaymentでエラーが発生しました')
        },
        async addReserveTemp(reserveData:string){ //予約仮登録
            console.log('addReserveTemp')
            const result = await firebase.firestore.save.reserve.tempSave(reserveData);
            if(!result){
                return false;
            }else {
                return true;
            }
            // throw new Error('addReserveTempでエラーが発生しました')
        },
        async createZoomMeeting(reserveData:any){
            if(data.settingData.zoom){
            console.log('createZoomMeeting',reserveData)
            try{
                const payload = {
                    "topic": reserveData.title,
                    "type": "2",
                    "start_time": reserveData.start,
                    "timezone": "Asia/Tokyo",
                    "settings": {
                      "use_pmi": "false"
                    },
                    "uid": data.reserveUser.uid,
                  }
                const zoomUrl = await cloudFunctions.getZoomIDV2(payload);
                return zoomUrl.data;
            }catch(e){
                console.log(e)
                throw new Error('createZoomMeetingでエラーが発生しました')
            }
            
            }else{
                return true;
            };
        },
        async sendEmail(zoomUrl:any,reserveData:any){
            console.log('sendEmail zoom',zoomUrl,reserveData)
            // throw new Error('sendEmailでエラーが発生しました')
            const reserveDate = dayjs(reserveData.start).format('YYYY年M月D日HH時mm分') + '～' + dayjs(reserveData.end).format('HH時mm分');
            const paymentDate = dayjs(reserveData.start).format('YYYY年M月D日');
            data.reserveUser.reserveDate = reserveDate;
            if(zoomUrl){
                console.log('zoomUrl',zoomUrl.split('?'))
                const zoomUrlLabel = zoomUrl.split('?');
            const msg = {
                testFlag:false,
                templateId: 'd-957a2dab508e4d6fbf2bdc0de8d1266b',
                from: "reservesystem@infom.jp",
                to: data.reserveUser.email,
                      replyTo: data.settingData.replyMailAddress,
                dynamic_template_data: {
                  clientName: data.reserveUser.name,
                  subject: reserveData.plan ? `${data.reserveUser.selectedPlan.title}の予約を受け付けました` : `${data.reserveUser.selectedPlan.title}の支払いが完了しました`,
                  item:data.reserveUser.plans,
                  mainTextBefore: data.settingData.mainTextBefore,
                  mainTextAfter: data.settingData.mainTextAfter,
                  signature: data.settingData.signature,
                  reserveDate: reserveDate,
                  to: data.reserveUser.email,
                  replyTo: data.settingData.replyMailAddress,
                  courceName: data.reserveUser.selectedPlan.title,
                  zoomUrl:(typeof zoomUrl === 'string') ? zoomUrl : '予約無し決済のみ',
                  zoomUrlLabel: (typeof zoomUrl === 'string') ? zoomUrlLabel[0]: "",
                }
              }
            await method.dashboard.sendMailTemplate(msg)
            }else if(!zoomUrl && !reserveData.paymentOnly){
                const msg = {
                    testFlag:false,
                    templateId: 'd-f9d74dd16ba24094b05003f48d1640a7',
                    from: "reservesystem@infom.jp",
                    to: data.reserveUser.email,
                      replyTo: data.settingData.replyMailAddress,
                    dynamic_template_data: {
                      clientName: data.reserveUser.name,
                      subject: `${reserveData.title}の予約が完了しました`,
                      item:data.reserveUser.plans,
                      courceName: data.reserveUser.selectedPlan.title,
                      mainTextBefore: data.settingData.mainTextBefore,
                      mainTextAfter: data.settingData.mainTextAfter,
                      signature: data.settingData.signature,
                      reserveDate: reserveDate,
                      to: data.reserveUser.email,
                      replyTo: data.settingData.replyMailAddress,
                    }
                  }
                await method.dashboard.sendMailTemplate(msg)
            }else if(reserveData.paymentOnly){
                const msg = {
                    testFlag:false,
                    templateId: 'd-b1e267b2f4aa4fd3845d6740c990995d',
                    from: "reservesystem@infom.jp",
                    to: data.settingData.replyMailAddress,
                      replyTo: data.reserveUser.email,
                    dynamic_template_data: {
                      clientName: data.settingData.name,
                      subject: `${reserveData.title}の支払いがありました`,
                      item:data.reserveUser.plans,
                      courceName: data.reserveUser.selectedPlan.title,
                      mainTextBefore: data.settingData.mainTextBefore,
                      mainTextAfter: data.settingData.mainTextAfter,
                      signature: data.settingData.signature,
                      reserveDate: paymentDate,
                      to: data.reserveUser.email,
                      replyTo: data.settingData.replyMailAddress,
                      name: data.reserveUser.name,
                      email: data.reserveUser.email,
                    }
                  }
                await method.dashboard.sendMailTemplate(msg)
            }
        },
        async addReserve(zoomUrl:any, reserveData:any) {
            console.log('addReserve', data.reserveUser, 'reserveData', reserveData);
            data.orderFinishUser.paymentId = reserveData.paymentId || '';
            data.orderFinishUser.name = data.reserveUser.name || '';
            data.orderFinishUser.email = data.reserveUser.email || '';
            data.orderFinishUser.title = reserveData.title || '';
            data.orderFinishUser.uid = data.reserveUser.uid || '';
            data.orderFinishUser.zoomUrl = zoomUrl || '';
            data.orderFinishUser.start = reserveData.start || '';
            data.orderFinishUser.end = reserveData.end || '';
            data.orderFinishUser.orderDate = data.reserveUser.reserveDate || `${dayjs().format('YYYY年M月D日')}`;
            data.orderFinishUser.paymentOnly = reserveData.paymentOnly || false;
            
            // await method.order.setOrderData(data.reserveUser);
            await firebase.firestore.save.reserve.reserveSave(reserveData.id || '');
        },
        async ejectReserveTemp(reserveDateId:string){
            reserveDateId ? firebase.firestore.save.reserve.tempReturn(reserveDateId) : null;
            await method.calendar.eventLoad()
            console.log('ejectReserveTemp',data.calendar.events)
        },
        async eject(router:any,reserveData:any,e=''){
            await this.ejectReserveTemp(reserveData.id)
            localStorage.removeItem('reserveData')
            localStorage.removeItem('reserveUser')
            localStorage.removeItem('settingData')
            router.back();
            data.calendar.showModal = false;
            console.log('予約エラー',e)
        },
        async reserveComplete(reserveData:any){
            console.log('reserveComplete',reserveData)
            if(!reserveData.paymentOnly){
            const msg = {
                testFlag:false,
                templateId: 'd-f0804f3e6bfb40a991d0a404b2bd5648',
                from: "reservesystem@infom.jp",
                replyTo: data.settingData.replyMailAddress,
                to: data.settingData.replyMailAddress,
                dynamic_template_data: {
                  clientName: data.settingData.name,
                  name: data.reserveUser.name,
                  email: data.reserveUser.email,
                  subject: `${data.reserveUser.selectedPlan.title}の新規予約を受け付けました`,
                  item:data.reserveUser.plans,
                  reserveDate: data.reserveUser.reserveDate,
                  replyTo: data.settingData.replyMailAddress,
                  to: data.settingData.replyMailAddress,
                  courceName: data.reserveUser.selectedPlan.title,
                }
              }
            await method.dashboard.sendMailTemplate(msg)
            } else {
                const msg = {
                    testFlag:false,
                    templateId: 'd-df50c2427e7a4026912f95c951ff7814',
                    from: "reservesystem@infom.jp",
                    replyTo: data.settingData.replyMailAddress,
                    to: data.reserveUser.email,
                    dynamic_template_data: {
                        clientName: data.settingData.name,
                        name: data.reserveUser.name,
                        email: data.reserveUser.email,
                        subject: `${data.reserveUser.selectedPlan.title}の支払いが完了しました`,
                        item:data.reserveUser.plans,
                        reserveDate: data.reserveUser.reserveDate,
                        replyTo: data.settingData.replyMailAddress,
                        to: data.settingData.replyMailAddress,
                        signature: data.settingData.signature,
                        courceName: data.reserveUser.selectedPlan.title,
                    }
                    }
                await method.dashboard.sendMailTemplate(msg)
        }
        }

    },
    dashboard:{
        saveItem(){
            console.log('save')
            const invalidPlans = data.loginUser.reservePlans.filter((plan: any) => {
                return plan.title === '' || plan.price === '';
            });
            if (invalidPlans.length > 0) {
                alert('商品名と価格は必須です');
            } else {
                firebase.firestore.save.plan();
                alert('保存しました')
            }
        },
        loadItem(){

        },
        async saveSettings(){
            const result1 = await this.saveSettingData();
            const result2 = await this.saveCustomerSecret();
            if(result1 && result2){
                alert('保存しました')
                data.customerSecret = {};
                return true;
            }else{
                alert('保存に失敗しました')
                return false;
            }
        },
        async saveSettingData(){
            const result = await firebase.firestore.save.settingData();
            if(result){
                return true;
            }else{
                return false;
            }
        },
        async saveCustomerSecret(){
            // const result = await firebase.firestore.save.customerSecret();
            const customerSecret = method.dashboard.filterObject(data.customerSecret)
            customerSecret.uid = data.loginUser.uid;
            const result = await cloudFunctions.writeSecretKeys(customerSecret);
            if(result){
                return true;
            }else{
                return false;
            }
        },
        async sendTestMail(){
            console.log('sendTestMail')
            const msg = {
                testFlag:true,
                templateId: 'd-957a2dab508e4d6fbf2bdc0de8d1266b',
                to: data.settingData.testMailAddress,
                from: "reservesystem@infom.jp",
                replyTo: data.settingData.replyMailAddress,
                dynamic_template_data: {
                  clientName: "テスト名",
                  subject: "テストコースの予約を受け付けました",
                  item:"テストコース",
                  mainTextBefore: data.settingData.mainTextBefore,
                  mainTextAfter: data.settingData.mainTextAfter,
                  signature: data.settingData.signature,
                  reserveDate: "2022年7月19日10時00分～11時00分",
                  zoomUrl:"#",
                  zoomUrlLabel: "zoomURLがここに入ります",
                }
              }
              console.log('msg',msg);
              data.settingData.clientResponse = true;
              data.settingData.clientResponse = await method.dashboard.sendMailTemplate(msg);
              setTimeout(() => data.settingData.clientResponse = false, 5000);
        },
        addItem(){
            data.loginUser.reservePlans.push
            ({
                id: '',
                title: '',
                price: 0,
                description: '',
                date: [
                    {
                        start: '',
                        end: '',
                    },
                ],
                cource:[],
                delete:false,
                use:true,
                color:'#000',
                shipping:false,
            })
        },
        subItem(index:number){
            if(data.loginUser.reservePlans.length === 1){
                alert('商品（プラン）は最低1つ必要です')
                return
            } else if(data.loginUser.reservePlans.length > 1 && data.loginUser.reservePlans[index].id){
                // 削除確認のアラートを出す。OKなら削除する
                const result = confirm('削除しますか？')
                if(result){
                    // method.firestore.deleteItem(data.loginUser.reservePlans[index].id);
                    // data.loginUser.reservePlans.splice(index,1)

                }else{
                    return
                }
            } else if(data.loginUser.reservePlans.length > 1){
                data.loginUser.reservePlans.splice(index,1)
            }
        },
        async sendMailTemplate(msg:any){
            console.log('sendMailTemplate',msg)
            const result = await cloudFunctions.sendMailTemplateV2(msg);
            if(result){
                return true;
            } else {
                throw new Error('sendMailTemplateでエラーが発生しました')
            }
        },
        existPlansCheck(){ //イベント内に登録されているプランが、プラン一覧に存在しない場合、プラン一覧に追加する
            data.dashboardCalendar.selectedEventDetails.forEach(eventDetail => {
                // data.loginUser.reservePlansをループ
                data.loginUser.reservePlans.forEach(reservePlan => {
                  // 現在のreservePlanがeventDetail.plan配列に存在するかチェック
                  const isPlanAdded = eventDetail.plan.some(plan => plan.id === reservePlan.id);
              
                  // reservePlanがeventDetail.plan配列に存在しない場合、追加する
                  if (!isPlanAdded) {
                    eventDetail.plan.push({
                      id: reservePlan.id,
                      title: reservePlan.title,
                      price: reservePlan.price,
                      description: reservePlan.description,
                      date: reservePlan.date,
                    //   selected: reservePlan.use,
                    });
                  }
                });
              });
        },
        filterObject(obj: { [key: string]: any }): { [key: string]: any } {
            const result: { [key: string]: any } = {};
            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (value !== "" && value !== null && value !== undefined) {
                  if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                    const nestedFilteredObject = this.filterObject(value); // 再帰的にフィルタリング
                    if (Object.keys(nestedFilteredObject).length > 0) {
                      result[key] = nestedFilteredObject;
                    }
                  } else {
                    result[key] = value;
                  }
                }
              }
            }
            return result;
        },
        addCource(){
            console.log('addCource')
        }
    },
    stripe: {
        async createAccount() {
            const stripeData = data.settingData.stripe;
            if(stripeData.test){
                console.log('Stripeテストモード')
                if (stripeData.connectedAccountIdTest) {
                    console.log("Account already created. ID:", stripeData.connectedAccountIdTest);
                    await method.stripe.startOnboarding(stripeData.connectedAccountIdTest);
                return;
                }
        
                stripeData.accountCreatePending = true;
                stripeData.error = false;
        
                try {
                    console.log("Calling createAccount Cloud Function");
                    const result:any = await cloudFunctions.createAccount(data.settingData.stripe);
                    console.log("CreateAccount result:", result);
                    stripeData.connectedAccountIdTest = result.data.account;
                    await firebase.firestore.save.settingData();
                    stripeData.accountCreatePending = false;
                    // await this.startOnboarding(stripeData.connectedAccountIdTest);
                    await method.stripe.startOnboarding(stripeData.connectedAccountIdTest);
                } catch (error) {
                    stripeData.accountCreatePending = false;
                    stripeData.error = true;
                    console.error("Error creating account:", error);
                }
            } else {
                if (stripeData.connectedAccountId) {
                    console.log("Account already created. ID:", stripeData.connectedAccountId);
                    await method.stripe.startOnboarding(stripeData.connectedAccountId);
                return;
                }
        
                stripeData.accountCreatePending = true;
                stripeData.error = false;
        
                try {
                    console.log("Calling createAccount Cloud Function");
                    const result:any = await cloudFunctions.createAccount(data.settingData.stripe);
                    console.log("CreateAccount result:", result);
                    stripeData.connectedAccountId = result.data.account;
                    await firebase.firestore.save.settingData();
                    stripeData.accountCreatePending = false;
                    // await this.startOnboarding(stripeData.connectedAccountId);
                    await method.stripe.startOnboarding(stripeData.connectedAccountId);
                } catch (error) {
                    stripeData.accountCreatePending = false;
                    stripeData.error = true;
                    console.error("Error creating account:", error);
                }
            }
        },
    
        async fetchClientSecret(accountId:any) {
            try {
            console.log("Calling createAccountSession Cloud Function with account ID:", accountId);
            const result:any = await cloudFunctions.createAccountSession({ account: accountId }, data.settingData.stripe.test);
            console.log("CreateAccountSession result:", result);
            return result.data.clientSecret;
            } catch (error) {
            console.error("Error fetching client secret:", error);
            throw error;
            }
        },
    
        async startOnboarding(accountId:any) {
            const stripeData:any = data.settingData.stripe;
            console.log("Starting onboarding for account ID:", accountId);
    
            const fetchClientSecret = async () => {
            return await this.fetchClientSecret(accountId);
            };
    
            try {
                let apiKey = '';
                if(data.settingData.stripe.test){
                    apiKey = "pk_test_51KnbpQKHcUWT6obFi4U5TXqitzGTg6xiYRnIgXQV6jbCmoAkFv6maTIKsVngdk41XwkSFs6A3TCWwonRn1OI0hIT00rALEOHng"
                } else {
                    apiKey = "pk_live_51KnbpQKHcUWT6obFlkBjScT3JEonO0hMaUbUORgJkRefqlrweERVEWzSZniPRh66frNDLoxYhbNlZvlvsNkqJm6h000IjqaA5Y"
                }
            stripeData.stripeConnectInstance = await loadConnectAndInitialize({
                publishableKey: apiKey,
                fetchClientSecret,
                appearance: {
                overlays: "dialog",
                variables: {
                    colorPrimary: "#635BFF",
                },
                },
            });
    
            const container = document.getElementById("embedded-onboarding-container");
            if (container) {
                const embeddedOnboardingComponent = stripeData.stripeConnectInstance.create("account-onboarding");
                embeddedOnboardingComponent.setOnExit(() => {
                stripeData.onboardingExited = true;
                console.log('User exited the onboarding flow');
                });
                container.appendChild(embeddedOnboardingComponent);
            } else {
                console.error("Embedded onboarding container not found");
            }
            } catch (error) {
            console.error("Error initializing Stripe Connect:", error);
            }
        },
        async loginLink(){
            const link = await cloudFunctions.createLoginlink(data.settingData.stripe);
            console.log('loginLink',link)
            return link;
        }
    },
    reservedPage:{
        editItem(item:any){
            console.log('editItem')
            if(!item.paymentOnly){
                data.reservedPage.dialog = true;
                data.reservedPage.editedItem = item;
            }else{
                data.reservedPage.dialogPaymentOnly = true;
                data.reservedPage.editedItem = item;
            }
        },
        close(){
            console.log('close')
            data.reservedPage.dialog = false;
            data.reservedPage.dialogPaymentOnly = false;
            data.reservedPage.editedItem = {
                name:'',
                email:'',
                zoomUrl:'',
                reserveId:'',
                paymentId:'',
                orderDate:'',
                uid:'',
                title:'',
                orderId:'',
                start:'',
                end:'',
            }
        },
        closeDelete(){
            console.log('closeDelete')
            data.reservedPage.dialogDelete = false;
            data.reservedPage.dialogPaymentOnlyDelete = false;
        },
        deleteItemConfirm(event:any){
            console.log('deleteItemConfirm',event)
            firebase.firestore.delete.reserve(event);
            data.dashboardCalendar.events = data.dashboardCalendar.events.filter((item:any) => item.id !== event.reserveId);
            data.reservedPage.serverItems = data.orderFinishUsers.filter((item:any) => item.orderId !== event.orderId);
            console.log('deleteItemConfirm',data.dashboardCalendar.events,data.reservedPage.serverItems)
            data.reservedPage.dialogDelete = false;
            data.reservedPage.dialogPaymentOnlyDelete = false
        },
        deleteItem(item:any){
            console.log('deleteItem')
            if(!item.paymentOnly){
                data.reservedPage.dialogDelete = item;
            }else{
                data.reservedPage.dialogPaymentOnlyDelete = item;
            }
        },
        async save(){
            console.log('reservedPage save')
            await firebase.firestore.save.order();
            data.reservedPage.editedItem = {
                name:'',
                email:'',
                zoomUrl:'',
                reserveId:'',
                paymentId:'',
                orderDate:'',
                uid:'',
                title:'',
                orderId:'',
                start:'',
                end:'',
            }
        }


    },
    firestore:{
        addItem(item:any){
            console.log('addItem')

        },
        deleteItem(id:string){
            console.log('deleteItem')
        },
    },
    signUp:{
        async submit(router:any){
            console.log('submit')
            const result = await firebase.userRegister(data.loginUser.email,data.loginUser.password);
            if(result && 'user' in result){
                data.loginUser.uid = result.user.uid;
                data.settingData.name = data.loginUser.name;
                data.settingData.replyMailAddress = data.loginUser.email;
                console.log('singup submit', data.loginUser.uid)
                await firebase.firestore.save.settingData();
                router.push('/Dashboard');
            } else {
                console.log('登録エラー',result)
            }
        }
    },
    order: {
        setOrderData(data:any) {
            data.orderFinishUser = {
                name: data.name,
                email: data.email,
                reservedId: data.reservedId || '',
                paymentId: data.paymentId || '',
                zoomUrl: data.zoomUrl || '',
                title: data.selectedPlan?.title || '',
                uid: data.uid || '',
            };
            console.log('setOrderData', data.orderFinishUser);
        }
    },
    shipping:{
        load:{
            async plan(){
                const plans = await firebase.firestore.load.plans();
                if(plans){
                    data.loginUser.reservePlans = plans;
                }
            }
        }
    }
});