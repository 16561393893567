<template>
    <v-container>
      <!-- 予約プランが未登録の場合 -->
      <v-row>
        <v-col v-if="!userStore.reservePlans.length">
          <v-btn @click="addPlan">プランの作成を始める</v-btn>
        </v-col>
      </v-row>
      <!-- 予約プラン一覧 -->
      <v-row v-for="(item, index) in userStore.reservePlans" :key="item.id || index">
        <v-col cols="12" sm="6" md="4">
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="item.title"
                label="プラン名"
                :disabled="item.delete"
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="item.price"
                label="税込価格"
                :disabled="item.delete"
              ></v-text-field>
              <v-textarea
                v-model="item.description"
                label="説明"
                :disabled="item.delete"
              ></v-textarea>
              <v-color-picker
                v-model="item.color"
                label="イベントカラー"
              ></v-color-picker>
              <v-switch
                v-model="item.shipping"
                color="blue"
                label="カレンダー予約無しで購入可能にする"
              ></v-switch>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="addPlan">プランを追加する</v-btn>
              <v-btn v-if="userStore.reservePlans.length > 1 && !item.delete" @click="markPlanDeleted(item)">
                プランを削除する
              </v-btn>
              <v-btn v-if="item.delete" @click="restorePlan(item)">
                プランを戻す
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <!-- 保存ボタン -->
      <v-row>
        <v-col>
          <v-btn @click="savePlans">保存する</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script setup lang="ts">
  import { useUserStore } from '@/stores/userStore'
  import { ref } from 'vue'
  
  const userStore = useUserStore();
  
  // 新規プランを追加する
  const addPlan = () => {
    // 新規プランの初期オブジェクト（必要に応じてIDの生成方法などを変更）
    const newPlan = {
      id: '', // 新規の場合は空文字または生成したIDを設定
      title: '',
      price: 0,
      description: '',
      color: '',
      shipping: false,
      use: true,
      paymentId: '',
      paymentOnly: false,
      delete: false,
    };
    userStore.reservePlans.push(newPlan);
  };
  
  // 削除フラグをオンにする
  const markPlanDeleted = (plan: any) => {
    plan.delete = true;
  };
  
  // 削除フラグをオフにする
  const restorePlan = (plan: any) => {
    plan.delete = false;
  };
  
  // すべてのプランを保存する（userStore 側のアクションを呼び出す）
  const savePlans = async () => {
    try {
      await userStore.savePlans();
      alert('プランが保存されました');
    } catch (error) {
      console.error('保存エラー', error);
      alert('プランの保存に失敗しました');
    }
  };
  </script>
  