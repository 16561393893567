import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { useUserStore } from '@/stores/userStore'
  import { ref } from 'vue'
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'PlanList',
  setup(__props) {

  const userStore = useUserStore();
  
  // 新規プランを追加する
  const addPlan = () => {
    // 新規プランの初期オブジェクト（必要に応じてIDの生成方法などを変更）
    const newPlan = {
      id: '', // 新規の場合は空文字または生成したIDを設定
      title: '',
      price: 0,
      description: '',
      color: '',
      shipping: false,
      use: true,
      paymentId: '',
      paymentOnly: false,
      delete: false,
    };
    userStore.reservePlans.push(newPlan);
  };
  
  // 削除フラグをオンにする
  const markPlanDeleted = (plan: any) => {
    plan.delete = true;
  };
  
  // 削除フラグをオフにする
  const restorePlan = (plan: any) => {
    plan.delete = false;
  };
  
  // すべてのプランを保存する（userStore 側のアクションを呼び出す）
  const savePlans = async () => {
    try {
      await userStore.savePlans();
      alert('プランが保存されました');
    } catch (error) {
      console.error('保存エラー', error);
      alert('プランの保存に失敗しました');
    }
  };
  
return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_color_picker = _resolveComponent("v-color-picker")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          (!_unref(userStore).reservePlans.length)
            ? (_openBlock(), _createBlock(_component_v_col, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, { onClick: addPlan }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("プランの作成を始める")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(userStore).reservePlans, (item, index) => {
        return (_openBlock(), _createBlock(_component_v_row, {
          key: item.id || index
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              sm: "6",
              md: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: item.title,
                          "onUpdate:modelValue": ($event: any) => ((item.title) = $event),
                          label: "プラン名",
                          disabled: item.delete
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: item.price,
                          "onUpdate:modelValue": ($event: any) => ((item.price) = $event),
                          label: "税込価格",
                          disabled: item.delete
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
                        _createVNode(_component_v_textarea, {
                          modelValue: item.description,
                          "onUpdate:modelValue": ($event: any) => ((item.description) = $event),
                          label: "説明",
                          disabled: item.delete
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
                        _createVNode(_component_v_color_picker, {
                          modelValue: item.color,
                          "onUpdate:modelValue": ($event: any) => ((item.color) = $event),
                          label: "イベントカラー"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                        _createVNode(_component_v_switch, {
                          modelValue: item.shipping,
                          "onUpdate:modelValue": ($event: any) => ((item.shipping) = $event),
                          color: "blue",
                          label: "カレンダー予約無しで購入可能にする"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, { onClick: addPlan }, {
                          default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createTextVNode("プランを追加する")
                          ])),
                          _: 1
                        }),
                        (_unref(userStore).reservePlans.length > 1 && !item.delete)
                          ? (_openBlock(), _createBlock(_component_v_btn, {
                              key: 0,
                              onClick: ($event: any) => (markPlanDeleted(item))
                            }, {
                              default: _withCtx(() => _cache[2] || (_cache[2] = [
                                _createTextVNode(" プランを削除する ")
                              ])),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true),
                        (item.delete)
                          ? (_openBlock(), _createBlock(_component_v_btn, {
                              key: 1,
                              onClick: ($event: any) => (restorePlan(item))
                            }, {
                              default: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createTextVNode(" プランを戻す ")
                              ])),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128)),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, { onClick: savePlans }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("保存する")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})