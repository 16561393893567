import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { useReserveStore } from '@/stores/reserveStore'
  import { useUserStore } from '@/stores/userStore' // ログインユーザーの情報（reservePlans を含む）を管理するストア
  import type { ReservePlan } from '@/types/CalendarEvent';



  
export default /*@__PURE__*/_defineComponent({
  __name: 'shipping',
  setup(__props) {

  const router = useRouter()
  const route = useRoute()
  const reserveStore = useReserveStore()
  const userStore = useUserStore()
  
  // 購入用プランは、ユーザーストアの reservePlans のうち shipping:true のもの
  const shippingPlans = ref<ReservePlan[]>([]);
  
  onMounted(async () => {
    // ローカルストレージの不要なデータをクリア
    localStorage.removeItem('reserveData')
    localStorage.removeItem('reserveUser')
    localStorage.removeItem('settingData')
  
    // URLパラメータから uid を取得し、購入用ユーザー情報に設定
    const uid = Array.isArray(route.params.uid) ? route.params.uid[0] : route.params.uid
    reserveStore.reserveUser.uid = uid
  
    // ユーザーストアから購入用プランをロード（例: firestoreService.fetchPlans を利用）
    await userStore.loadReservePlans(uid)
    shippingPlans.value = userStore.reservePlans.filter((plan: any) => plan.shipping)
  })
  
  // 決済開始処理。各ストアに実装した決済開始アクションを呼び出す前提です
  const submitShipping = async () => {
    if (reserveStore.reserveUser.selectedPlan) {
      // 決済フラグ（shipping用の場合、paymentOnly を true に設定）
      reserveStore.reserveUser.selectedPlan.paymentOnly = true
    }
    reserveStore.reserveUser.submit = true
    // 決済処理開始（submitShipping アクションは、決済処理（クレジット/店舗決済）のロジックを実装している前提）
    await reserveStore.submitShipping(router, reserveStore.reserveUser.selectedPlan)
  }
  
return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("商品購入ページ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_form, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_radio_group, {
                            modelValue: _unref(reserveStore).reserveUser.selectedPlan,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(reserveStore).reserveUser.selectedPlan) = $event))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shippingPlans.value, (plan) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: plan.id
                                }, [
                                  (plan.shipping)
                                    ? (_openBlock(), _createBlock(_component_v_radio, {
                                        key: 0,
                                        label: `${plan.title} - ${plan.price}円`,
                                        value: plan
                                      }, null, 8, ["label", "value"]))
                                    : _createCommentVNode("", true),
                                  (plan.shipping)
                                    ? (_openBlock(), _createBlock(_component_v_textarea, {
                                        key: 1,
                                        readonly: "",
                                        modelValue: plan.description,
                                        "onUpdate:modelValue": ($event: any) => ((plan.description) = $event)
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"]),
                          _createVNode(_component_v_text_field, {
                            modelValue: _unref(reserveStore).reserveUser.name,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(reserveStore).reserveUser.name) = $event)),
                            label: "名前"
                          }, null, 8, ["modelValue"]),
                          _createVNode(_component_v_text_field, {
                            modelValue: _unref(reserveStore).reserveUser.email,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(reserveStore).reserveUser.email) = $event)),
                            label: "メールアドレス"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        onClick: submitShipping,
                        disabled: !_unref(reserveStore).reserveUser.name ||
                           !_unref(reserveStore).reserveUser.email ||
                           !_unref(reserveStore).reserveUser.selectedPlan?.id ||
                           _unref(reserveStore).reserveUser.submit
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" 決済ページへ移動する ")
                        ])),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})