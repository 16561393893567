import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

import { computed } from 'vue'
import { useSettingsStore } from '@/stores/settingsStore'
import { useCustomerSecretStore } from '@/stores/customerSecretStore'
import { useAuthStore } from '@/stores/authStore'
import StripeaccountCreate from '@/components/StripeaccountCreate.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Settings',
  setup(__props) {

const settingStore = useSettingsStore();
const customerSecretStore = useCustomerSecretStore();
const authStore = useAuthStore();

const isLogin = computed(() => {
  // 例として userStore の uid を参照してログイン判定する場合
  return authStore.isLogin; 
});

const saveSettings = async () => {
  await settingStore.saveSettings();
  await customerSecretStore.saveCustomerSecret();
};

const createStripeAccount = async () => {
  await settingStore.createStripeAccount();
};

const sendTestMail = async () => {
  await settingStore.sendTestMail();
};

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "mb-16" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { lg: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                label: "お名前",
                modelValue: _unref(settingStore).settingData.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(settingStore).settingData.name) = $event))
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    location: "start",
                    bottom: ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                        default: _withCtx(() => _cache[20] || (_cache[20] = [
                          _createTextVNode(" mdi-help-circle-outline ")
                        ])),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _cache[21] || (_cache[21] = _createTextVNode(" メール本文に表示される名前です。 "))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { lg: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                label: "返信先メールアドレス",
                modelValue: _unref(settingStore).settingData.replyMailAddress,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(settingStore).settingData.replyMailAddress) = $event))
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    location: "start",
                    bottom: ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                        default: _withCtx(() => _cache[22] || (_cache[22] = [
                          _createTextVNode(" mdi-help-circle-outline ")
                        ])),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _cache[23] || (_cache[23] = _createTextVNode(" 返信先のメールアドレスです。 "))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { lg: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                label: "予約可能日表示用カレンダーID",
                modelValue: _unref(settingStore).settingData.calendarId,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(settingStore).settingData.calendarId) = $event))
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    location: "start",
                    bottom: ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                        default: _withCtx(() => _cache[24] || (_cache[24] = [
                          _createTextVNode(" mdi-help-circle-outline ")
                        ])),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _cache[25] || (_cache[25] = _createTextVNode(" 予約可能日を追加したいGoogleカレンダーのIDを入力してください。 "))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { lg: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                label: "予定読み込み用カレンダーID",
                modelValue: _unref(settingStore).settingData.readOnlyCalendarId,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(settingStore).settingData.readOnlyCalendarId) = $event))
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    location: "start",
                    bottom: ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                        default: _withCtx(() => _cache[26] || (_cache[26] = [
                          _createTextVNode(" mdi-help-circle-outline ")
                        ])),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _cache[27] || (_cache[27] = _createTextVNode(" 「予約追加日」のページで表示させたいGoogleカレンダーのIDを入力してください。 "))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { lg: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _unref(settingStore).settingData.testMailAddress,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(settingStore).settingData.testMailAddress) = $event)),
                label: "テストメールの送信先"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_v_btn, {
                onClick: sendTestMail,
                disabled: _unref(settingStore).settingData.clientResponse
              }, {
                default: _withCtx(() => _cache[28] || (_cache[28] = [
                  _createTextVNode(" テストメールを送信する ")
                ])),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { lg: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_textarea, {
                label: "メール本文の前半部",
                modelValue: _unref(settingStore).settingData.mainTextBefore,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(settingStore).settingData.mainTextBefore) = $event))
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    location: "start",
                    bottom: ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                        default: _withCtx(() => _cache[29] || (_cache[29] = [
                          _createTextVNode(" mdi-help-circle-outline ")
                        ])),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _cache[30] || (_cache[30] = _createTextVNode(" メール本文のお客様名と日程の間の部分です。詳しいレイアウトはテストメールを送信して確認をお願いします。 "))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { lg: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_textarea, {
                label: "メール本文の後半部",
                modelValue: _unref(settingStore).settingData.mainTextAfter,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(settingStore).settingData.mainTextAfter) = $event))
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    location: "start",
                    bottom: ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                        default: _withCtx(() => _cache[31] || (_cache[31] = [
                          _createTextVNode(" mdi-help-circle-outline ")
                        ])),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _cache[32] || (_cache[32] = _createTextVNode(" メール本文の日程と署名の間の部分です。詳しいレイアウトはテストメールを送信して確認をお願いします。 "))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { lg: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_textarea, {
                label: "メール署名",
                modelValue: _unref(settingStore).settingData.signature,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(settingStore).settingData.signature) = $event))
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_v_tooltip, {
                    location: "start",
                    bottom: ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                        default: _withCtx(() => _cache[33] || (_cache[33] = [
                          _createTextVNode(" mdi-help-circle-outline ")
                        ])),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _cache[34] || (_cache[34] = _createTextVNode(" メール下部に表示される署名です。 "))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { lg: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_switch, {
                disabled: !isLogin.value,
                color: "blue",
                modelValue: _unref(settingStore).settingData.zoom,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(settingStore).settingData.zoom) = $event)),
                label: "ZOOMの自動ミーティング作成を有効にする"
              }, null, 8, ["disabled", "modelValue"]),
              _cache[35] || (_cache[35] = _createTextVNode(" このスイッチを有効にすると、カレンダーからの予約時に自動でZOOMのミーティングを作成します。 "))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_unref(settingStore).settingData.zoom)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 0,
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { lg: "4" }, {
                default: _withCtx(() => [
                  _cache[38] || (_cache[38] = _createTextVNode(" 以下の項目を4つ埋めて、保存してください。ただしセキュリティのため、ページを移動すると必ず空欄になります。 ")),
                  _createVNode(_component_v_text_field, {
                    label: "ZOOM ID",
                    modelValue: _unref(customerSecretStore).customerSecret.zoomId,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(customerSecretStore).customerSecret.zoomId) = $event))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        location: "start",
                        bottom: ""
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                            default: _withCtx(() => _cache[36] || (_cache[36] = [
                              _createTextVNode(" mdi-help-circle-outline ")
                            ])),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          _cache[37] || (_cache[37] = _createTextVNode(" お持ちのzoomアカウントのIDを入力してください。（通常はメールアドレスです。） "))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_unref(settingStore).settingData.zoom)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 1,
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { lg: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    label: "ZOOM accountId",
                    modelValue: _unref(customerSecretStore).customerSecret.accountId,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(customerSecretStore).customerSecret.accountId) = $event))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        location: "start",
                        bottom: ""
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                            default: _withCtx(() => _cache[39] || (_cache[39] = [
                              _createTextVNode(" mdi-help-circle-outline ")
                            ])),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          _cache[40] || (_cache[40] = _createTextVNode(" ZOOMのDeveloperで登録したaccountIdを入力してください。 "))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_unref(settingStore).settingData.zoom)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 2,
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { lg: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    label: "ZOOM clientId",
                    modelValue: _unref(customerSecretStore).customerSecret.clientId,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(customerSecretStore).customerSecret.clientId) = $event))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        location: "start",
                        bottom: ""
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                            default: _withCtx(() => _cache[41] || (_cache[41] = [
                              _createTextVNode(" mdi-help-circle-outline ")
                            ])),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          _cache[42] || (_cache[42] = _createTextVNode(" ZOOMのDeveloperで登録したclientIdを入力してください。 "))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_unref(settingStore).settingData.zoom)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 3,
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { lg: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    label: "ZOOM clientSecret",
                    modelValue: _unref(customerSecretStore).customerSecret.clientSecret,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(customerSecretStore).customerSecret.clientSecret) = $event))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        location: "start",
                        bottom: ""
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                            default: _withCtx(() => _cache[43] || (_cache[43] = [
                              _createTextVNode(" mdi-help-circle-outline ")
                            ])),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          _cache[44] || (_cache[44] = _createTextVNode(" ZOOMのDeveloperで登録したclientSecretを入力してください。 "))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_unref(customerSecretStore).customerSecret.SquarePayment)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 4,
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { lg: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    label: "Square LocationId テスト環境用",
                    modelValue: _unref(settingStore).settingData.squareLocationIdTest,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(settingStore).settingData.squareLocationIdTest) = $event))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        location: "start",
                        bottom: ""
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                            default: _withCtx(() => _cache[45] || (_cache[45] = [
                              _createTextVNode(" mdi-help-circle-outline ")
                            ])),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          _cache[46] || (_cache[46] = _createTextVNode(" SquareのDeveloperで発行したlocationIDのテスト用キーです。 "))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_unref(customerSecretStore).customerSecret.SquarePayment)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 5,
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { lg: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    label: "Square AppID テスト環境用",
                    modelValue: _unref(settingStore).settingData.squareAppIdTest,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(settingStore).settingData.squareAppIdTest) = $event))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        location: "start",
                        bottom: ""
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                            default: _withCtx(() => _cache[47] || (_cache[47] = [
                              _createTextVNode(" mdi-help-circle-outline ")
                            ])),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          _cache[48] || (_cache[48] = _createTextVNode(" SquareのDeveloperで発行したAppIDのテスト用キーです。 "))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_unref(customerSecretStore).customerSecret.SquarePayment)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 6,
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { lg: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    label: "Square Access token テスト環境用",
                    modelValue: _unref(customerSecretStore).customerSecret.squareAccessTokenTest,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(customerSecretStore).customerSecret.squareAccessTokenTest) = $event))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        location: "start",
                        bottom: ""
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                            default: _withCtx(() => _cache[49] || (_cache[49] = [
                              _createTextVNode(" mdi-help-circle-outline ")
                            ])),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          _cache[50] || (_cache[50] = _createTextVNode(" SquareのDeveloperで発行したアクセストークンです。 "))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_unref(customerSecretStore).customerSecret.SquarePayment)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 7,
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { lg: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    label: "Square LocationID",
                    modelValue: _unref(settingStore).settingData.squareLocationId,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(settingStore).settingData.squareLocationId) = $event))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        location: "start",
                        bottom: ""
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                            default: _withCtx(() => _cache[51] || (_cache[51] = [
                              _createTextVNode(" mdi-help-circle-outline ")
                            ])),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          _cache[52] || (_cache[52] = _createTextVNode(" SquareのDeveloperで発行したロケーションIDです。 "))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_unref(customerSecretStore).customerSecret.SquarePayment)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 8,
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { lg: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    label: "Square AppID",
                    modelValue: _unref(settingStore).settingData.squareAppId,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_unref(settingStore).settingData.squareAppId) = $event))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        location: "start",
                        bottom: ""
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                            default: _withCtx(() => _cache[53] || (_cache[53] = [
                              _createTextVNode(" mdi-help-circle-outline ")
                            ])),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          _cache[54] || (_cache[54] = _createTextVNode(" SquareのDeveloperで発行したAppIDです。 "))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_unref(customerSecretStore).customerSecret.SquarePayment)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 9,
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { lg: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    label: "Square Access token",
                    modelValue: _unref(customerSecretStore).customerSecret.squareAccessToken,
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_unref(customerSecretStore).customerSecret.squareAccessToken) = $event))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        location: "start",
                        bottom: ""
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                            default: _withCtx(() => _cache[55] || (_cache[55] = [
                              _createTextVNode(" mdi-help-circle-outline ")
                            ])),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          _cache[56] || (_cache[56] = _createTextVNode(" SquareのDeveloperで発行したアクセストークンです。 "))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { lg: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_switch, {
                disabled: !isLogin.value,
                color: "blue",
                modelValue: _unref(settingStore).settingData.stripe.use,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_unref(settingStore).settingData.stripe.use) = $event)),
                label: "予約時のStripe決済を有効にする"
              }, null, 8, ["disabled", "modelValue"]),
              _cache[57] || (_cache[57] = _createTextVNode(" このスイッチを有効にすると、Stripeクレジット決済が有効になります。 "))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_unref(settingStore).settingData.stripe.use)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 10,
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { lg: "4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_dialog, { "max-width": "800" }, {
                    activator: _withCtx(({ props: activatorProps }) => [
                      _createVNode(_component_v_btn, _mergeProps(activatorProps, {
                        text: "",
                        onClick: createStripeAccount
                      }), {
                        default: _withCtx(() => _cache[58] || (_cache[58] = [
                          _createTextVNode(" Stripeの設定 ")
                        ])),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(({ isActive }) => [
                      _createVNode(StripeaccountCreate)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_btn, {
        onClick: saveSettings,
        style: {"position":"fixed","bottom":"5%","right":"5%"}
      }, {
        default: _withCtx(() => _cache[59] || (_cache[59] = [
          _createTextVNode(" 保存する ")
        ])),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})