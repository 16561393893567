<template>
    <v-container fill-height>
      <v-row align-content="center" justify="center">
        <v-col lg="6" align="center">
          <v-card>
            <v-card-title>お申し込みが完了しました</v-card-title>
            <v-card-text>
              <p>お申し込みが完了いたしました。</p>
              <p>
                ご入力頂いたメールアドレス宛に、詳細を記載したものを送信しますので、ご確認下さい。
              </p>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn @click="backHome">元のページへ戻る</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script setup lang="ts">
  import { onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useReserveStore } from '@/stores/reserveStore'
  
  const reserveStore = useReserveStore()
  const router = useRouter()
  const route = useRoute()
  
  const backHome = () => {
    // reserveUser.uid をストアから参照して戻るページを決定
    location.href = `${window.location.origin}/${route.params.uid}/`
  }
  
  onMounted(() => {
    // 完了後、ストアの状態をリセット（必要に応じてローカルストレージもクリア）
    reserveStore.$reset()
    localStorage.clear()
  })
  </script>
  