import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useReserveStore } from '@/stores/reserveStore'
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'ReserveComplete',
  setup(__props) {

  const reserveStore = useReserveStore()
  const router = useRouter()
  const route = useRoute()
  
  const backHome = () => {
    // reserveUser.uid をストアから参照して戻るページを決定
    location.href = `${window.location.origin}/${route.params.uid}/`
  }
  
  onMounted(() => {
    // 完了後、ストアの状態をリセット（必要に応じてローカルストレージもクリア）
    reserveStore.$reset()
    localStorage.clear()
  })
  
return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { "fill-height": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        "align-content": "center",
        justify: "center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            lg: "6",
            align: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("お申し込みが完了しました")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createElementVNode("p", null, "お申し込みが完了いたしました。", -1),
                      _createElementVNode("p", null, " ご入力頂いたメールアドレス宛に、詳細を記載したものを送信しますので、ご確認下さい。 ", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, { class: "justify-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, { onClick: backHome }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("元のページへ戻る")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})