// src/stores/settingsStore.ts
import { defineStore } from 'pinia'
import { firestoreService } from '@/services/firebaseService'
import { useAuthStore } from '@/stores/authStore'
import type { SettingData } from '@/types/SettingTypes'
import { cloudFunctions } from '@/comporsables/useCloudFunctions'

const initialSettingData: SettingData = {
  payment: false,
  zoom: false,
  name: '',
  mailAddress: '',
  replyMailAddress: '',
  subject: '',
  mainTextBefore: '',
  mainTextAfter: '',
  color: '',
  slackWebhook: '',
  squareLocationIdTest: '',
  squareAppIdTest: '',
  squareLocationId: '',
  squareAppId: '',
  signature: '',
  testmode: false,
  testMailAddress: '',
  clientResponse: false,
  stripe: {
    accountCreatePending: false,
    onboardingExited: false,
    error: false,
    connectedAccountId: null,
    connectedAccountIdTest: null,
    stripeConnectInstance: null,
    test: false,
    use: false,
  },
  stores: {
    id: '',
    use: false,
  },
  calendarId: '',
}

export interface SettingsStoreState {
  settingData: SettingData;
  loading: boolean;
}

export const useSettingsStore = defineStore('settings', {
  state: (): SettingsStoreState => ({
    settingData: initialSettingData,
    loading: false,
  }),
  actions: {
    async load() {
      const auth = useAuthStore()
      if (!auth.uid) throw new Error('ユーザー未ログイン')
      this.loading = true
      // ※ ここはユーザーデータから設定情報を取得する場合の例です。
      const data = await firestoreService.getUserData(auth.uid)
      if (data) {
        // 取得したデータを SettingData 型にキャストまたは変換します
        this.settingData = data as SettingData
      }
      this.loading = false
    },
    async save() {
      const auth = useAuthStore()
      if (!auth.uid) throw new Error('ユーザー未ログイン')
      await firestoreService.saveUserSettings(auth.uid, this.settingData)
    },
    async saveSettings() {
      try {
        await this.save()
        alert('設定が保存されました')
      } catch (error) {
        console.error('設定保存エラー', error)
        alert('設定の保存に失敗しました')
      }
    },
    async createStripeAccount() {
      try {
        // Stripeアカウント作成処理を実装（例：cloudFunctions の呼び出し）
        alert('Stripeアカウント作成処理を実行しました')
      } catch (error) {
        console.error('Stripeアカウント作成エラー', error)
        alert('Stripeアカウント作成に失敗しました')
      }
    },
    // sendMailTemplate は実際のメール送信処理を行う関数に置き換えてください
    async sendMailTemplate(msg: any): Promise<boolean> {
        console.log('sendMailTemplate',msg)
        const result = await cloudFunctions.sendMailTemplateV2(msg);
        if(result){
            return true;
        } else {
            throw new Error('sendMailTemplateでエラーが発生しました')
        }
      },
    async sendTestMail() {
      try {
        console.log('sendTestMail')
        const msg = {
          testFlag: true,
          templateId: 'd-957a2dab508e4d6fbf2bdc0de8d1266b',
          to: this.settingData.testMailAddress,
          from: "reservesystem@infom.jp",
          replyTo: this.settingData.replyMailAddress,
          dynamic_template_data: {
            clientName: "テスト名",
            subject: "テストコースの予約を受け付けました",
            item: "テストコース",
            mainTextBefore: this.settingData.mainTextBefore,
            mainTextAfter: this.settingData.mainTextAfter,
            signature: this.settingData.signature,
            reserveDate: "2022年7月19日10時00分～11時00分",
            zoomUrl: "#",
            zoomUrlLabel: "zoomURLがここに入ります",
          },
        }
        console.log('msg', msg)
        // clientResponse を一旦 true に設定
        this.settingData.clientResponse = true
        // メール送信処理（sendMailTemplate）を呼び出し、その結果を clientResponse にセット
        const response = await this.sendMailTemplate(msg)
        this.settingData.clientResponse = response
        setTimeout(() => {
          this.settingData.clientResponse = false
        }, 5000)
      } catch (error) {
        console.error('テストメール送信エラー', error)
        alert('テストメールの送信に失敗しました')
      }
    },
  },
})
