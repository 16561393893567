// src/services/ZoomService.ts
import { cloudFunctions } from '@/services/cloudFunctions';

export const ZoomService = {
  async createMeeting(payload: any): Promise<any> {
    try {
      const response = await cloudFunctions.getZoomIDV2(payload);
      return response.data;
    } catch (e) {
      console.error("ZoomService.createMeeting", e);
      throw new Error('Failed to create Zoom meeting');
    }
  },
};
