// src/services/EmailService.ts

import { cloudFunctions } from '@/services/cloudFunctions';

export const EmailService = {
    async sendConfirmationEmail(emailData: any): Promise<void> {
      // ここでメール送信サービスへ委譲する
      console.log("EmailService.sendConfirmationEmail", emailData);
      // 例: await dashboardService.sendMailTemplate(emailData);
    },
    async sendMailTemplate(msg: any): Promise<boolean> {
      console.log('sendMailTemplate',msg)
      // ここでメール送信サービスへ委譲する
      const result = await cloudFunctions.sendMailTemplateV2(msg);
      if(result){
          return true;
      } else {
          throw new Error('sendMailTemplateでエラーが発生しました')
      }
    }
  };
  