// src/stores/customerSecretStore.ts
import { defineStore } from 'pinia';
import type { CustomerSecret } from '@/types/CustomerSecretTypes';
import { firestoreService } from '@/services/firebaseService';
import { useAuthStore } from '@/stores/authStore';
import { cloudFunctions } from '@/services/cloudFunctions';

export interface CustomerSecretStoreState {
  customerSecret: CustomerSecret;
}

export const useCustomerSecretStore = defineStore('customerSecret', {
  state: (): CustomerSecretStoreState => ({
    customerSecret: {
      zoomId: '',
      accountId: '',
      clientId: '',
      clientSecret: '',
      uid:'',
      // SquarePayment: false,
      // SquareTestMode: false,
      // squareAccessTokenTest: '',
      // squareAccessToken: '',
    },
  }),
  actions: {
    async saveCustomerSecret(){
      // 保存処理
      const auth = useAuthStore()
            if (!auth.uid) throw new Error('ユーザー未ログイン')
              this.customerSecret.uid = auth.uid
            await cloudFunctions.writeSecretKeys(this.customerSecret)
    }
  }
});
