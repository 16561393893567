// src/services/ErrorHandler.ts
export const ErrorHandler = {
    async handleReservationError(
      router: any,
      reserveData: any,
      error: any,
      cancelTempReservation: (id: string) => Promise<void>
    ) {
      console.error("Reservation error", error);
      if (reserveData?.id) {
        await cancelTempReservation(reserveData.id);
      }
      localStorage.removeItem('reserveData');
      localStorage.removeItem('reserveUser');
      localStorage.removeItem('settingData');
      router.back();
    },
  };
  