import { firestoreService } from '@/services/firebaseService';
import type { CalendarEvent } from '@/types/CalendarEvent';

export const calendarService = {
  async fetchEvents(uid: string): Promise<CalendarEvent[]> {
    const result = await firestoreService.fetchReserveDays(uid);
    return result
      .filter((event: any) => !event.orderId)  // orderId が空の場合のみ残す
      .map((event: any) => {
        if (!event.id) {
          throw new Error('取得したイベントに id がありません');
        }
        // start と end が存在しない場合は、現在時刻をデフォルトにする
        const start = event.start ? (event.start instanceof Date ? event.start : new Date(event.start)) : new Date();
        const end = event.end ? (event.end instanceof Date ? event.end : new Date(event.end)) : new Date();
        
        return {
          ...event,
          start,
          end,
        } as CalendarEvent;
      });
  },
  

  async deleteEvent(event: CalendarEvent): Promise<void> {
    if (!event.id) {
      throw new Error('削除するイベントには有効な id が必要です');
    }
    const orderId = event.orderId || '';
    await firestoreService.deleteReserve(event.id, orderId);
  },

  async saveEvent(event: CalendarEvent): Promise<void> {
    const order = {
      ...event,
      reserveId: event.id,
    };
    await firestoreService.saveOrder(order);
  }
};
