<template>
  <v-app>
    <v-app-bar-nav-icon v-if="route.meta.dashboard" @click.stop="drawer = !drawer" />
    <v-main>
      <v-navigation-drawer v-if="route.meta.dashboard" v-model="drawer">
        <v-list>
          <v-list-item
            v-for="item in dashboardPageList"
            :key="item.to"
            @click="router.push(item.to)"
            :disabled="!auth.loggedIn"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-spacer />
        <v-list>
          <v-list-item @click="auth.logout(router)">
            <v-list-item-title>ログアウト</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/authStore'

const route = useRoute()
const router = useRouter()
const drawer = ref(false)
const auth = useAuthStore()

const dashboardPageList = [
  { title: 'ダッシュボードトップ', to: '/Dashboard' },
  { title: '各種設定',       to: '/Settings' },
  { title: '予約一覧',       to: '/ReserveList' },
  { title: 'プラン一覧',     to: '/PlanList' },
  { title: '予約日追加',     to: '/AddReserveCalendar' },
]
</script>
