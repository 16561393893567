// src/services/PaymentService.ts
import { cloudFunctions } from '@/services/cloudFunctions';

export type PaymentData = {
  successUrl: string;
  cancelUrl: string;
  lineItems?: any[];
  email: string;
  amount: number;
  paymentMethodId?: string[];
  mode: string;
  customerId: string;
  description: string;
  receiptEmail: string;
  metadata: any;
  test: boolean;
  connectedAccountId: string;
  uid: string;
};

export const PaymentService = {
  async processStripePayment(paymentData: PaymentData): Promise<any> {
    try {
      const sessionId: any = await cloudFunctions.stripePaymentV2(paymentData);
      return sessionId;
    } catch (e) {
      console.error("PaymentService.processStripePayment", e);
      throw e;
    }
  },
  async processStoresPayment(paymentData: PaymentData): Promise<any> {
    try {
      const paymentUrl: any = await cloudFunctions.storesPayment(paymentData);
      return paymentUrl;
    } catch (e) {
      console.error("PaymentService.processStoresPayment", e);
      throw e;
    }
  },
};
