<template>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>商品購入ページ</v-card-title>
            <v-card-text>
              <v-form>
                <v-radio-group v-model="reserveStore.reserveUser.selectedPlan">
                  <div v-for="plan in shippingPlans" :key="plan.id">
                    <v-radio
                      v-if="plan.shipping"
                      :label="`${plan.title} - ${plan.price}円`"
                      :value="plan"
                    ></v-radio>
                    <v-textarea
                      readonly
                      v-if="plan.shipping"
                      v-model="plan.description"
                    ></v-textarea>
                  </div>
                </v-radio-group>
                <v-text-field
                  v-model="reserveStore.reserveUser.name"
                  label="名前"
                ></v-text-field>
                <v-text-field
                  v-model="reserveStore.reserveUser.email"
                  label="メールアドレス"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="submitShipping"
                :disabled="!reserveStore.reserveUser.name ||
                           !reserveStore.reserveUser.email ||
                           !reserveStore.reserveUser.selectedPlan?.id ||
                           reserveStore.reserveUser.submit"
              >
                決済ページへ移動する
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { useReserveStore } from '@/stores/reserveStore'
  import { useUserStore } from '@/stores/userStore' // ログインユーザーの情報（reservePlans を含む）を管理するストア
  import type { ReservePlan } from '@/types/CalendarEvent';



  const router = useRouter()
  const route = useRoute()
  const reserveStore = useReserveStore()
  const userStore = useUserStore()
  
  // 購入用プランは、ユーザーストアの reservePlans のうち shipping:true のもの
  const shippingPlans = ref<ReservePlan[]>([]);
  
  onMounted(async () => {
    // ローカルストレージの不要なデータをクリア
    localStorage.removeItem('reserveData')
    localStorage.removeItem('reserveUser')
    localStorage.removeItem('settingData')
  
    // URLパラメータから uid を取得し、購入用ユーザー情報に設定
    const uid = Array.isArray(route.params.uid) ? route.params.uid[0] : route.params.uid
    reserveStore.reserveUser.uid = uid
  
    // ユーザーストアから購入用プランをロード（例: firestoreService.fetchPlans を利用）
    await userStore.loadReservePlans(uid)
    shippingPlans.value = userStore.reservePlans.filter((plan: any) => plan.shipping)
  })
  
  // 決済開始処理。各ストアに実装した決済開始アクションを呼び出す前提です
  const submitShipping = async () => {
    if (reserveStore.reserveUser.selectedPlan) {
      // 決済フラグ（shipping用の場合、paymentOnly を true に設定）
      reserveStore.reserveUser.selectedPlan.paymentOnly = true
    }
    reserveStore.reserveUser.submit = true
    // 決済処理開始（submitShipping アクションは、決済処理（クレジット/店舗決済）のロジックを実装している前提）
    await reserveStore.submitShipping(router, reserveStore.reserveUser.selectedPlan)
  }
  </script>
  