import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

import { ref, onMounted, computed,onUnmounted } from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { useCalendarStore } from '@/stores/calendarStore'
import { useReservationStore } from '@/stores/reservationStore'
import { useAuthStore } from '@/stores/authStore'
import { useSettingsStore } from '@/stores/settingsStore'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'


export default /*@__PURE__*/_defineComponent({
  __name: 'Home',
  setup(__props) {

const calendarStore = useCalendarStore()
const reservationStore = useReservationStore()
const authStore = useAuthStore()
const settingsStore = useSettingsStore()

// 予約者情報はローカル管理（ここでは reserveUser に uid、名前、メール、選択プランなどを保持）
const reserveUser = ref({
  name: '',
  email: '',
  // selectedPlan はプランのIDまたはプランオブジェクトのうち必要な部分（ここでは ID を保持）
  selectedPlan: '',
  submit: false,
  uid: '',
  // reserveDate は予約日時文字列として後でセットする
  reserveDate: ''
})

// 予約確認ダイアログの表示状態
const confirmReservationDialog = ref(false)

const router = useRouter()
const route = useRoute()

const windowWidth = ref(window.innerWidth)
const updateWindowWidth = () => {
  windowWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener('resize', updateWindowWidth)
})
onUnmounted(() => {
  window.removeEventListener('resize', updateWindowWidth)
})

const calendarOptions = computed(() => {
  let view = 'dayGridMonth'
  if (windowWidth.value < 600) {
    view = 'dayGridDay' // 小さい画面では1日表示
  } else if (windowWidth.value < 900) {
    view = 'dayGridWeek' // 中程度の画面では週表示
  } else {
    view = 'dayGridMonth' // 大きい画面では月表示
  }

  return {
    initialView: view,
    locale: 'ja-JP',
    plugins: [dayGridPlugin, interactionPlugin],
    events: calendarStore.events,
    eventClick: calendarStore.select,
    eventContent: (arg: any) => {
      const timeText = arg.timeText;
      const selectedPlanId = arg.event.extendedProps.selectedPlan;
      const planArray = arg.event.extendedProps.plan || [];
      const planObj = planArray.find((p: any) => p.id === selectedPlanId);
      const planName = planObj ? planObj.title : '';
      const color = arg.event.backgroundColor || arg.event.extendedProps.color || '#3788d8';
      const markerHtml = `<span style="
        background-color: ${color};
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 4px;
      "></span>`;
      const html = `<div>${markerHtml}<strong>${timeText}</strong> ${planName}</div>`;
      return { html };
    },
  };
});



// 日付フォーマット関数
function formatDate(date: Date | string): string {
  return dayjs(date).format('MM月DD日 HH時mm分')
}

function closeModal() {
  calendarStore.showModal = false
}

/**
 * 予約確認ダイアログで「はい」が押された場合に呼ばれる
 */
async function handleConfirmReservation() {
  confirmReservationDialog.value = false
  await submitReservation()
}

/**
 * submitReservation のメイン処理
 * ・選択されたイベント（1件のみ）を reserveData として取得
 * ・reservationStore.tempSave() で仮予約を実行
 * ・確認用ページ（/reserve）へ遷移
 * ・設定データのチェック後、支払いが必要なら reservationStore.creditPayment() を実行
 *   ※支払い不要の場合は、Zoom ミーティング作成等の正式予約処理 submitSection2() を実行
 */
async function submitReservation() {
  reserveUser.value.submit = true
  let reserveData: any = null
  try {
    // if (calendarStore.selectedEventDetails.length > 1) {
    //   alert('複数の予約は出来ません')
    //   return
    // }
    reserveData = calendarStore.selected
    if (!reserveData || !reserveData.id) return

    // ① 仮予約（tempSave）
    const tempResult = await reservationStore.tempSave(reserveData.id)
    if (!tempResult) {
      alert('仮予約に失敗しました')
      return
    }

    // ② 予約確認用ページ（/reserve）へ遷移
    router.push('/reserve')

    // ③ 設定データの読み込み
    await settingsStore.load()
    if (!settingsStore.settingData.replyMailAddress) {
      alert('返信先メールアドレスが設定されていません')
      await reservationStore.cancel(reserveData.id)
      router.back()
      calendarStore.showModal = false
      return
    }

    // ④ 支払い金額の算出
    let paymentPrice = 0
    if (reserveData.plan && reserveData.plan.length > 0) {
      const selectedPlan = reserveData.plan.find((p: any) => p.id === reserveData.selectedPlanId)
      paymentPrice = selectedPlan ? parseInt(selectedPlan.price) : 0
    } else {
      paymentPrice = reserveData.price || 0
    }
    console.log('paymentPrice:', paymentPrice)

    // ⑤ 決済処理が必要な場合
    if ((settingsStore.settingData.stripe.use && paymentPrice !== 0) ||
        (settingsStore.settingData.stores.use && paymentPrice !== 0)) {
      if (settingsStore.settingData.stripe.use && !settingsStore.settingData.stripe.connectedAccountId) {
        alert('Stripeのアカウントが設定されていません。設定ページからStripeのアカウント設定をお願いします。')
        await reservationStore.cancel(reserveData.id)
        router.back()
        calendarStore.showModal = false
        return
      }
      const creditResult = await reservationStore.creditPayment(
        reserveData,
        reserveUser.value,
        settingsStore.settingData
      )
      if (!creditResult) {
        alert('クレジット決済に失敗しました')
        await reservationStore.cancel(reserveData.id)
        router.back()
        calendarStore.showModal = false
        return
      }
      // ※決済処理の場合、window.location.href で遷移するため、以降の処理は実行されない可能性がある
    } else {
      // ⑥ 支払い不要の場合：正式予約処理の続行
      localStorage.setItem('reserveData', JSON.stringify(reserveData))
      localStorage.setItem('reserveUser', JSON.stringify(reserveUser.value))
      localStorage.setItem('settingData', JSON.stringify(settingsStore.settingData))
      router.push(`/${authStore.uid}/reserve`)
    }
  } catch (e: any) {
    console.error('Reservation submit error', e)
    alert('予約処理に失敗しました')
    if (reserveData?.id) {
      await reservationStore.cancel(reserveData.id)
    }
  } finally {
    reserveUser.value.submit = false
  }
}

/**
 * submitSection2：Zoom ミーティング作成、正式予約登録、メール送信、予約完了処理
 */
async function submitSection2(reserveData: any) {
  try {
    let zoomResult = null
    if (settingsStore.settingData.zoom && !reserveData.paymentOnly) {
      zoomResult = await reservationStore.createZoomMeeting(reserveData, reserveUser.value)
      if (!zoomResult) {
        alert('Zoomミーティングの作成に失敗しました')
        await reservationStore.cancel(reserveData.id)
        router.back()
        calendarStore.showModal = false
        return
      }
      console.log('zoomResult:', zoomResult)
    }
    await reservationStore.addReserve(zoomResult, reserveData)
    await reservationStore.reserveComplete(reserveData, settingsStore.settingData, reserveUser.value)
    await reservationStore.sendEmail(zoomResult, reserveData, settingsStore.settingData, reserveUser.value)
    router.push('/ReserveComplete')
  } catch (e: any) {
    await reservationStore.eject(router, reserveData, e)
  }
}

onMounted(async () => {
  // URL パラメータから uid を取得
  const uid = Array.isArray(route.params.uid) ? route.params.uid[0] : route.params.uid
  reserveUser.value.uid = uid
  authStore.uid = uid;

  const temp = localStorage.getItem('reserveData')
  if (temp) {
    const tempReserveData = JSON.parse(temp)
    await reservationStore.cancel(tempReserveData.id)
    localStorage.clear()
  } else {
    await calendarStore.load(uid)
    console.log('calendarStore.events',calendarStore.events)
  }

  // ※ もし必要なら、route.params.plan0 などに応じたフィルタ処理も追加
})

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_unref(FullCalendar), { options: calendarOptions.value }, null, 8, ["options"]),
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_dialog, {
                    modelValue: _unref(calendarStore).showModal,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(calendarStore).showModal) = $event)),
                    "max-width": "500px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, { lg: "6" }, {
                        default: _withCtx(() => [
                          (_unref(calendarStore).selected)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                _createVNode(_component_v_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(calendarStore).selected.title), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" 開始時間：" + _toDisplayString(formatDate(_unref(calendarStore).selected.start)), 1),
                                    _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                                    _createTextVNode(" 終了時間：" + _toDisplayString(formatDate(_unref(calendarStore).selected.end)) + " ", 1),
                                    _createElementVNode("div", null, [
                                      _createVNode(_component_v_text_field, {
                                        modelValue: reserveUser.value.name,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((reserveUser.value.name) = $event)),
                                        label: "お名前"
                                      }, null, 8, ["modelValue"]),
                                      _createVNode(_component_v_text_field, {
                                        modelValue: reserveUser.value.email,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((reserveUser.value.email) = $event)),
                                        label: "メールアドレス"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _createVNode(_component_v_radio_group, {
                                      modelValue: reserveUser.value.selectedPlan,
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((reserveUser.value.selectedPlan) = $event))
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(calendarStore).selected.plan, (plan) => {
                                          return (_openBlock(), _createElementBlock("div", {
                                            key: plan.id
                                          }, [
                                            (_unref(calendarStore).selected.selectedPlanId === plan.id)
                                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                  _createVNode(_component_v_radio, {
                                                    label: plan.price ? `料金：${plan.price}円` : '無料',
                                                    value: plan
                                                  }, null, 8, ["label", "value"]),
                                                  _createVNode(_component_v_textarea, {
                                                    readonly: "",
                                                    modelValue: plan.description,
                                                    "onUpdate:modelValue": ($event: any) => ((plan.description) = $event),
                                                    label: "プラン詳細"
                                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                                ], 64))
                                              : _createCommentVNode("", true)
                                          ]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                color: "primary",
                                onClick: closeModal,
                                disabled: reserveUser.value.submit
                              }, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode(" キャンセル ")
                                ])),
                                _: 1
                              }, 8, ["disabled"]),
                              _createVNode(_component_v_btn, {
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (confirmReservationDialog.value = true)),
                                disabled: !reserveUser.value.name || !reserveUser.value.email || !reserveUser.value.selectedPlan || reserveUser.value.submit
                              }, {
                                default: _withCtx(() => _cache[9] || (_cache[9] = [
                                  _createTextVNode(" 予約する ")
                                ])),
                                _: 1
                              }, 8, ["disabled"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_v_dialog, {
                    modelValue: confirmReservationDialog.value,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((confirmReservationDialog).value = $event)),
                    "max-width": "400px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "text-h5" }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode("予約確認")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode(" 本当に予約を確定してよろしいですか？ ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_btn, {
                                color: "blue darken-1",
                                text: "",
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (confirmReservationDialog.value = false))
                              }, {
                                default: _withCtx(() => _cache[12] || (_cache[12] = [
                                  _createTextVNode(" いいえ ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_btn, {
                                color: "blue darken-1",
                                text: "",
                                onClick: handleConfirmReservation
                              }, {
                                default: _withCtx(() => _cache[13] || (_cache[13] = [
                                  _createTextVNode(" はい ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_spacer)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})