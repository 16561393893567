import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "error"
}
const _hoisted_3 = {
  key: 2,
  class: "dev-callout"
}

import {data} from '@/comporsables/useData'
import {method} from '@/comporsables/useMethod'
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'StripeaccountCreate',
  setup(__props) {

const loginCheck = computed(() => {
    return data.loginUser.uid !== '';
});

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Stripeアカウント作成")
        ])),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          (!_unref(data).settingData.stripe.accountCreatePending && !_unref(data).settingData.stripe.connectedAccountId)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : _createCommentVNode("", true),
          _cache[1] || (_cache[1] = _createElementVNode("div", { id: "embedded-onboarding-container" }, null, -1)),
          (_unref(data).settingData.stripe.error)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, "エラーが発生しました!"))
            : _createCommentVNode("", true),
          (_unref(data).settingData.stripe.connectedAccountId || _unref(data).settingData.stripe.accountCreatePending)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})