<template>
    <v-container>
      <v-row justify="center">
        <v-col lg="6">
          <v-card>
            <v-card-title>新規登録</v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-text-field
                  v-model="user.name"
                  label="名前"
                  :rules="[v => !!v || '名前を入力してください']"
                ></v-text-field>
                <v-text-field
                  v-model="user.email"
                  label="メールアドレス"
                  :rules="[
                    v => !!v || 'メールアドレスを入力してください',
                    v => /.+@.+\..+/.test(v) || '有効なメールアドレスを入力してください'
                  ]"
                ></v-text-field>
                <v-text-field
                  v-model="user.password"
                  label="パスワード"
                  :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append-inner="toggleShow"
                  :rules="[v => v.length >= 6 || '最低6文字が必要です']"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="submitSignUp"
                :disabled="!isValid"
              >登録する</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script setup lang="ts">
  import { ref, computed } from 'vue'
  import { useRouter } from 'vue-router'
  import { useUserStore } from '@/stores/userStore'
  import { authService } from '@/services/firebaseService'
  
  // ログインユーザー情報は userStore で管理
  const userStore = useUserStore()
  const router = useRouter()
  
  // サインアップフォームのローカル状態
  const user = ref({
    name: '',
    email: '',
    password: ''
  })
  
  // パスワード表示切替用
  const show = ref(false)
  const toggleShow = () => {
    show.value = !show.value
  }
  
  // 入力チェック（最低限のバリデーション）
  const isValid = computed(() => {
    return (
      user.value.name.trim() !== '' &&
      user.value.email.trim() !== '' &&
      user.value.password.length >= 6
    )
  })
  
  // サインアップ処理
  const submitSignUp = async () => {
    if (!isValid.value) return
    try {
      // authService.register で登録処理を実行
      const res = await authService.register(user.value.email, user.value.password)
      // ユーザー登録成功後、userStore にユーザー情報をセット
      userStore.setUser({
        uid: res.user.uid,
        name: user.value.name,
        email: user.value.email,
        reservePlans: [] // 必要に応じて初期化
      })
      // 登録完了後はホームなど適切なページへリダイレクト
      router.push('/')
    } catch (error) {
      console.error('SignUp error', error)
      alert('サインアップに失敗しました')
    }
  }
  </script>
  