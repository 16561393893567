<template>
  <v-container class="mb-16">
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="お名前" v-model="settingStore.settingData.name">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              メール本文に表示される名前です。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="返信先メールアドレス" v-model="settingStore.settingData.replyMailAddress">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              返信先のメールアドレスです。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <!-- カレンダーID入力欄の追加 -->
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="予約可能日表示用カレンダーID" v-model="settingStore.settingData.calendarId">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              予約可能日を追加したいGoogleカレンダーのIDを入力してください。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <!-- カレンダーID入力欄の追加 -->
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field label="予定読み込み用カレンダーID" v-model="settingStore.settingData.readOnlyCalendarId">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              「予約追加日」のページで表示させたいGoogleカレンダーのIDを入力してください。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-text-field v-model="settingStore.settingData.testMailAddress" label="テストメールの送信先"></v-text-field>
        <v-btn @click="sendTestMail" :disabled="settingStore.settingData.clientResponse">
          テストメールを送信する
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-textarea label="メール本文の前半部" v-model="settingStore.settingData.mainTextBefore">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              メール本文のお客様名と日程の間の部分です。詳しいレイアウトはテストメールを送信して確認をお願いします。
            </v-tooltip>
          </template>
        </v-textarea>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-textarea label="メール本文の後半部" v-model="settingStore.settingData.mainTextAfter">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              メール本文の日程と署名の間の部分です。詳しいレイアウトはテストメールを送信して確認をお願いします。
            </v-tooltip>
          </template>
        </v-textarea>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-textarea label="メール署名" v-model="settingStore.settingData.signature">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              メール下部に表示される署名です。
            </v-tooltip>
          </template>
        </v-textarea>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-switch :disabled="!isLogin" color="blue" v-model="settingStore.settingData.zoom" label="ZOOMの自動ミーティング作成を有効にする"></v-switch>
        このスイッチを有効にすると、カレンダーからの予約時に自動でZOOMのミーティングを作成します。
      </v-col>
    </v-row>
    <v-row justify="center" v-if="settingStore.settingData.zoom">
      <v-col lg="4">
        以下の項目を4つ埋めて、保存してください。ただしセキュリティのため、ページを移動すると必ず空欄になります。
        <v-text-field label="ZOOM ID" v-model="customerSecretStore.customerSecret.zoomId">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              お持ちのzoomアカウントのIDを入力してください。（通常はメールアドレスです。）
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="settingStore.settingData.zoom">
      <v-col lg="4">
        <v-text-field label="ZOOM accountId" v-model="customerSecretStore.customerSecret.accountId">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              ZOOMのDeveloperで登録したaccountIdを入力してください。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="settingStore.settingData.zoom">
      <v-col lg="4">
        <v-text-field label="ZOOM clientId" v-model="customerSecretStore.customerSecret.clientId">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              ZOOMのDeveloperで登録したclientIdを入力してください。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="settingStore.settingData.zoom">
      <v-col lg="4">
        <v-text-field label="ZOOM clientSecret" v-model="customerSecretStore.customerSecret.clientSecret">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              ZOOMのDeveloperで登録したclientSecretを入力してください。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <!-- Square 関連の項目（必要に応じて） -->
    <v-row justify="center" v-if="customerSecretStore.customerSecret.SquarePayment">
      <v-col lg="4">
        <v-text-field label="Square LocationId テスト環境用" v-model="settingStore.settingData.squareLocationIdTest">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              SquareのDeveloperで発行したlocationIDのテスト用キーです。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="customerSecretStore.customerSecret.SquarePayment">
      <v-col lg="4">
        <v-text-field label="Square AppID テスト環境用" v-model="settingStore.settingData.squareAppIdTest">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              SquareのDeveloperで発行したAppIDのテスト用キーです。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="customerSecretStore.customerSecret.SquarePayment">
      <v-col lg="4">
        <v-text-field label="Square Access token テスト環境用" v-model="customerSecretStore.customerSecret.squareAccessTokenTest">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              SquareのDeveloperで発行したアクセストークンです。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="customerSecretStore.customerSecret.SquarePayment">
      <v-col lg="4">
        <v-text-field label="Square LocationID" v-model="settingStore.settingData.squareLocationId">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              SquareのDeveloperで発行したロケーションIDです。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="customerSecretStore.customerSecret.SquarePayment">
      <v-col lg="4">
        <v-text-field label="Square AppID" v-model="settingStore.settingData.squareAppId">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              SquareのDeveloperで発行したAppIDです。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="customerSecretStore.customerSecret.SquarePayment">
      <v-col lg="4">
        <v-text-field label="Square Access token" v-model="customerSecretStore.customerSecret.squareAccessToken">
          <template v-slot:prepend>
            <v-tooltip location="start" bottom>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              SquareのDeveloperで発行したアクセストークンです。
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="4">
        <v-switch :disabled="!isLogin" color="blue" v-model="settingStore.settingData.stripe.use" label="予約時のStripe決済を有効にする"></v-switch>
        このスイッチを有効にすると、Stripeクレジット決済が有効になります。
      </v-col>
    </v-row>
    <v-row justify="center" v-if="settingStore.settingData.stripe.use">
      <v-col lg="4">
        <v-dialog max-width="800">
          <template v-slot:activator="{ props: activatorProps }">
            <v-btn v-bind="activatorProps" text @click="createStripeAccount">
              Stripeの設定
            </v-btn>
          </template>
          <template v-slot:default="{ isActive }">
            <StripeaccountCreate></StripeaccountCreate>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
    <v-btn @click="saveSettings" style="position: fixed; bottom: 5%; right: 5%;">
      保存する
    </v-btn>
  </v-container>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useSettingsStore } from '@/stores/settingsStore'
import { useCustomerSecretStore } from '@/stores/customerSecretStore'
import { useAuthStore } from '@/stores/authStore'
import StripeaccountCreate from '@/components/StripeaccountCreate.vue'

const settingStore = useSettingsStore();
const customerSecretStore = useCustomerSecretStore();
const authStore = useAuthStore();

const isLogin = computed(() => {
  // 例として userStore の uid を参照してログイン判定する場合
  return authStore.isLogin; 
});

const saveSettings = async () => {
  await settingStore.saveSettings();
  await customerSecretStore.saveCustomerSecret();
};

const createStripeAccount = async () => {
  await settingStore.createStripeAccount();
};

const sendTestMail = async () => {
  await settingStore.sendTestMail();
};
</script>
