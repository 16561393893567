// src/services/TempReservationService.ts
import { firestoreService } from '@/services/firebaseService';

export const TempReservationService = {
    async addTempReservation(reserveDataId: string): Promise<boolean> {
      try {
        await firestoreService.tempReserve(reserveDataId, '');
        return true;
      } catch (e) {
        console.error("TempReservationService.addTempReservation", e);
        return false;
      }
    },
    async cancelTempReservation(reserveDataId: string): Promise<void> {
      try {
        await firestoreService.cancelTempReserve(reserveDataId);
      } catch (e) {
        console.error("TempReservationService.cancelTempReservation", e);
      }
    },
  };
  