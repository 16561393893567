import { app } from "./firebaseService";
import { getFunctions,httpsCallable,connectFunctionsEmulator } from 'firebase/functions';

const region = 'asia-northeast1'; //リージョン指定
const functions = getFunctions(app, region);
if(location.hostname === "localhost"){
  connectFunctionsEmulator(functions,"localhost",5000);
}

export const cloudFunctions = ({
    sendmail(reserveSet:any){
      console.log('メール送信 reserveSet',reserveSet)
      const sendmail = httpsCallable(functions,'sendmail');
      return sendmail(reserveSet);
    },
    async zoom(payload:any){
      console.log('payload',payload);
      const zoom = httpsCallable(functions,'getZoomID');
      const zoom_URL = await zoom(payload)
      console.log('zoom_URL',zoom_URL);
      return zoom_URL;
    },
    sendMailTemplateV2(data:any){
      console.log('sendMailTemplate')
      const sendmail = httpsCallable(functions,'sendMailTemplateV2');
      return sendmail(data);
    },
    getZoomIDV2(data:any){
      console.log('getZoomIDV2',data)
      const getZoomIDV2 = httpsCallable(functions,'getZoomIDV2');
      const zoom_URL = getZoomIDV2(data)
      return zoom_URL;
    },
    async stripePaymentV2(data:any){
      console.log('stripePayment')
      const stripePayment = httpsCallable(functions,'stripePaymentV2');
      const result = await stripePayment(data)
      return result;
    },
    async createAccountSession(data:any,test:boolean){
      console.log('createAccountSession')
      data.test = test;
      const createAccountSession = httpsCallable(functions,'createAccountSession');
      const result = await createAccountSession(data)
      return result;
    },
    async createAccount(data:any){
      console.log('createAccount')
      const createAccount = httpsCallable(functions,'createAccount');
      const result = await createAccount(data)
      return result;
    },
    async createLoginlink(data:any){
      console.log('createLoginlink')
      const createLoginlink = httpsCallable(functions,'stripeLoginLink');
      const result = await createLoginlink(data)
      return result;
    },
    async storesPayment(data:any){
      console.log('storesPayment')
      const getStoresPaymentUrl = httpsCallable(functions,'getStoresPaymentUrl');
      const result = await getStoresPaymentUrl(data)
      return result;
    },
    async writeSecretKeys (data:any)  {
      console.log('writeSecretKeys')
      const writeSecretKeys = httpsCallable(functions,'writeSecretKeys');
      const result = await writeSecretKeys(data)
      return result;
    },

    async createCalendarEvent(data:any){
      console.log('createCalendarEvent')
      const createCalendarEvent = httpsCallable(functions,'createCalendarEvent');
      const result = await createCalendarEvent(data)
      return result;
    },

    async deleteCalendarEvent(data:any){
      console.log('deleteCalendarEvent')
      const deleteCalendarEvent = httpsCallable(functions,'deleteCalendarEvent');
      const result = await deleteCalendarEvent(data)
      return result;
    },

    async googleCalendarEventUpdate(data:any){
      console.log('googleCalendarEventUpdate')
      const googleCalendarEventUpdate = httpsCallable(functions,'googleCalendarEventUpdate');
      const result = await googleCalendarEventUpdate(data)
      return result;
    },

    async getGoogleCalendarEvents(data:any){
      console.log('getGoogleCalendarEvents')
      const getGoogleCalendarEvents = httpsCallable(functions,'getGoogleCalendarEvents');
      const result = await getGoogleCalendarEvents(data)
      return result;
    },
})

// export async function zoom(payload:any){
//   console.log('payload',payload);
//   const zoom = httpsCallable(functions,'getZoomID');
//   const zoom_URL = await zoom(payload)
//   console.log('zoom_URL',zoom_URL);
//   return zoom_URL;
// }

// export async function getZoomAuthirzationCode(reserveSet:any){
//   console.log('getZoomAuthirzationCode')
//   const getZoomAuthirzationCode = httpsCallable(functions,'getAuthUrl');
//   const result = await getZoomAuthirzationCode(reserveSet)
//   console.log('zoomAuthirzationCode',result);
//   return result.data.zoomAuthUrl;
// }

// export async function sendmail(reserveSet:any){
//   console.log('メール送信 reserveSet',reserveSet)
//   const sendmail = httpsCallable(functions,'sendmail');
//   return sendmail(reserveSet);
// }

export async function sessionCreate(sessionData:any){
  console.log('sessionData',sessionData)
  const sessionCreate = httpsCallable(functions,'sessions');
  return sessionCreate(sessionData);
}

export async function sendMailTemplate(data:any){
  console.log('sendMailTemplate')
  const sendmail = httpsCallable(functions,'sendMailTemplate');
  return sendmail(data);
}

export async function paymentSquare(data:any){
  console.log('paymentSquare')
  const payment = httpsCallable(functions,'paymentSquare');
  return payment(data);
}

export async function paymentSquareActual(data:any){
  console.log('paymentSquareActual')
  const payment = httpsCallable(functions,'paymentSquareActual');
  return payment(data);
}

export async function paymentSquareCancel(data:any){
  console.log('paymentSquareCancel')
  const payment = httpsCallable(functions,'paymentSquareCancel');
  return payment(data);
}