import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useReservationStore } from '@/stores/reservationStore'


export default /*@__PURE__*/_defineComponent({
  __name: 'Reserve',
  setup(__props) {

// Reserve.vue の <script setup lang="ts"> 部分

const router = useRouter()
const route = useRoute()
const reservationStore = useReservationStore()

onMounted(async () => {
  // session_id または stores がクエリに含まれている場合
  if (route.query.session_id || route.query.stores) {

    if (route.query.session_id) {
      reservationStore.reserveData.paymentId = route.query.session_id as string
    } else if (route.query.stores) {
      reservationStore.reserveData.paymentId = reservationStore.settingData.stores?.id
    }
  }
    // ローカルストレージから予約データを読み込む
    await reservationStore.loadFromLocalStorage()
    console.log('stripe redirect reserveData', reservationStore.reserveData)
    
    // クエリパラメータに応じて paymentId を設定
    
    
    const reserveData = reservationStore.reserveData
    // ① 正式予約のコミット処理
    const commitSuccess = await reservationStore.commit(reserveData.id)
    if (!commitSuccess) {
      alert('正式予約に失敗しました')
      return
    }
    
    // ② Zoom ミーティング作成（設定で有効かつ paymentOnly でない場合）
    let zoomResult = null
    if (reservationStore.settingData.zoom && !reserveData.paymentOnly) {
      try {
        zoomResult = await reservationStore.createZoomMeeting(reserveData, reservationStore.reserveUser)
      } catch (e) {
        alert('Zoomミーティングの作成に失敗しました')
        await reservationStore.cancel(reserveData.id)
        router.back()
        return
      }
      console.log('zoomResult:', zoomResult)
    }
    
    // ③ 予約正式登録、完了処理、メール送信
    console.log('reserveData:', reserveData)
    console.log('settingData:', reservationStore.settingData)
    console.log('reserveUser:', reservationStore.reserveUser)
    await reservationStore.addReserve(zoomResult, reserveData,reservationStore.reserveUser)
    await reservationStore.reserveComplete(reserveData, reservationStore.settingData, reservationStore.reserveUser)
    await reservationStore.sendEmail(zoomResult, reserveData, reservationStore.settingData, reservationStore.reserveUser)
    
    // ④ 予約完了画面へ遷移
    router.push(`/${route.params.uid}/ReserveComplete`)
  })


return (_ctx: any,_cache: any) => {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "fill-height" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        align: "center",
        justify: "center",
        class: "fill-height"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            class: "text-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_progress_circular, {
                width: 7,
                size: 70,
                color: "primary",
                indeterminate: ""
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})