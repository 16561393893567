// src/stores/calendarStore.ts

import { defineStore } from 'pinia'
import type { CalendarEvent } from '@/types/CalendarEvent'
import { calendarService } from '@/services/calendarService'

export const useCalendarStore = defineStore('calendar', {
  state: () => ({
    events: [] as CalendarEvent[],
    selected: null as CalendarEvent | null,
    showModal: false,
    loading: false,              // ← 追加
  }),
  actions: {
    async load(uid: string) {
      this.loading = true         // ← 追加
      this.events = await calendarService.fetchEvents(uid)
      this.loading = false        // ← 追加
    },
    
    select(evt: CalendarEvent) {
      const ext = evt.event.extendedProps || {};
      const planArray = ext.plan || [];
      const selectedPlanId = ext.selectedPlan;
      // planArray から、selectedPlanId と一致するプランオブジェクトを探す
      const selectedPlanObj = planArray.find((p: any) => p.id === selectedPlanId);
      // 該当するプランがあれば、その title を利用。なければ元のタイトルをそのまま利用する
      const newTitle = selectedPlanObj ? selectedPlanObj.title : evt.event.title;
    
      // 必要なプロパティだけを抽出した新しいオブジェクトを作成
      const eventData = {
        id: evt.event.id,
        title: newTitle,
        start: evt.event.start,
        end: evt.event.end,
        // 他の必要な extendedProps のデータをそのまま保持する
        extendedProps: ext,
        plan:evt.event.extendedProps.plan,
        selectedPlanId:evt.event.extendedProps.selectedPlan,
      };
    
      this.selected = eventData;
      this.showModal = true;
    },
    
    clear() {
      this.selected = null
      this.showModal = false
    },
    async remove(id: string) {
      const event = this.events.find(e => e.id === id);
      if (!event) {
        // イベントが見つからない場合の処理（エラーを投げる、何もしない等）
        return;
      }
      await calendarService.deleteEvent(event);
      this.events = this.events.filter(e => e.id !== id);
    },    
    async add(evt: CalendarEvent) {
      await calendarService.saveEvent(evt)
      this.events.push(evt)
    }
  }
})
