import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, computed } from 'vue'
  import { useRouter } from 'vue-router'
  import { useUserStore } from '@/stores/userStore'
  import { authService } from '@/services/firebaseService'
  
  // ログインユーザー情報は userStore で管理
  
export default /*@__PURE__*/_defineComponent({
  __name: 'SignUp',
  setup(__props) {

  const userStore = useUserStore()
  const router = useRouter()
  
  // サインアップフォームのローカル状態
  const user = ref({
    name: '',
    email: '',
    password: ''
  })
  
  // パスワード表示切替用
  const show = ref(false)
  const toggleShow = () => {
    show.value = !show.value
  }
  
  // 入力チェック（最低限のバリデーション）
  const isValid = computed(() => {
    return (
      user.value.name.trim() !== '' &&
      user.value.email.trim() !== '' &&
      user.value.password.length >= 6
    )
  })
  
  // サインアップ処理
  const submitSignUp = async () => {
    if (!isValid.value) return
    try {
      // authService.register で登録処理を実行
      const res = await authService.register(user.value.email, user.value.password)
      // ユーザー登録成功後、userStore にユーザー情報をセット
      userStore.setUser({
        uid: res.user.uid,
        name: user.value.name,
        email: user.value.email,
        reservePlans: [] // 必要に応じて初期化
      })
      // 登録完了後はホームなど適切なページへリダイレクト
      router.push('/')
    } catch (error) {
      console.error('SignUp error', error)
      alert('サインアップに失敗しました')
    }
  }
  
return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { lg: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("新規登録")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_form, { ref: "form" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: user.value.name,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((user.value.name) = $event)),
                            label: "名前",
                            rules: [v => !!v || '名前を入力してください']
                          }, null, 8, ["modelValue", "rules"]),
                          _createVNode(_component_v_text_field, {
                            modelValue: user.value.email,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((user.value.email) = $event)),
                            label: "メールアドレス",
                            rules: [
                    v => !!v || 'メールアドレスを入力してください',
                    v => /.+@.+\..+/.test(v) || '有効なメールアドレスを入力してください'
                  ]
                          }, null, 8, ["modelValue", "rules"]),
                          _createVNode(_component_v_text_field, {
                            modelValue: user.value.password,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((user.value.password) = $event)),
                            label: "パスワード",
                            "append-inner-icon": show.value ? 'mdi-eye' : 'mdi-eye-off',
                            type: show.value ? 'text' : 'password',
                            "onClick:appendInner": toggleShow,
                            rules: [v => v.length >= 6 || '最低6文字が必要です']
                          }, null, 8, ["modelValue", "append-inner-icon", "type", "rules"])
                        ]),
                        _: 1
                      }, 512)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        onClick: submitSignUp,
                        disabled: !isValid.value
                      }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("登録する")
                        ])),
                        _: 1
                      }, 8, ["disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})