// src/stores/reservationStore.ts
import { defineStore } from 'pinia'
import { firestoreService } from '@/services/firebaseService'
import dayjs from 'dayjs'
import { cloudFunctions } from '@/services/cloudFunctions'
import { EmailService } from '@/services/EmailService'
import { useSettingsStore } from '@/stores/settingsStore';

export const useReservationStore = defineStore('reservation', {
  state: () => ({
    tempId: '',
    submitting: false,
    reserveData: {} as any,
    reserveUser: {} as any,
    settingData: {} as any,
  }),
  actions: {
    loadFromLocalStorage() {
      const tempReserveData = localStorage.getItem('reserveData')
      const tempReserveUser = localStorage.getItem('reserveUser')
      const tempSettingData = localStorage.getItem('settingData')
      if (tempReserveData) {
        this.reserveData = JSON.parse(tempReserveData)
      }
      if (tempReserveUser) {
        this.reserveUser = JSON.parse(tempReserveUser)
      }
      if (tempSettingData) {
        this.settingData = JSON.parse(tempSettingData)
      }
    },
    async tempSave(id: string) {
      this.submitting = true
      try {
        await firestoreService.tempReserve(id, '') // email は別ストアから取得する想定
        this.tempId = id
        return true
      } catch (error) {
        console.error('仮予約に失敗しました', error)
        return false
      } finally {
        this.submitting = false
      }
    },

    async commit(id: string) {
      if (!id) return false
      this.submitting = true
      try {
        await firestoreService.saveOrder({ reserveId: id })
        this.tempId = ''
        return true
      } finally {
        this.submitting = false
      }
    },

    async cancel(id: string) {
      if (!id) return false
      this.submitting = true
      try {
        await firestoreService.cancelTempReserve(id)
        this.tempId = ''
        return true
      } finally {
        this.submitting = false
      }
    },

    // ==========================
    // Stripe 用決済処理
    async creditPaymentStripe(reserveData: any, reserveUser: any, settingData: any): Promise<boolean> {
      console.log('creditPayment (Stripe)', reserveUser)
      const paymentData = {
        successUrl: `${window.location.origin}/${reserveUser.uid}/reserve?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${window.location.origin}/${reserveUser.uid}`,
        lineItems: [{
          price_data: {
            currency: 'jpy',
            product_data: {
              name: reserveUser.selectedPlan.title,
            },
            unit_amount: reserveUser.selectedPlan.price,
          },
          quantity: 1,
        }],
        email: reserveUser.email,
        ammount: reserveUser.selectedPlan.price,
        paymentMethodId: ['card'],
        mode: 'payment',
        customerId: reserveUser.uid,
        description: '予約料金',
        receiptEmail: reserveUser.email,
        metadata: {
          reserveId: reserveUser.reserveDate,
        },
        test: settingData.stripe.test,
        connectedAccountId: settingData.stripe.connectedAccountId,
        uid: reserveUser.uid,
      }
      console.log('Stripe paymentData:', paymentData)
      const sessionId: any = await cloudFunctions.stripePaymentV2(paymentData)
      console.log('Stripe sessionId:', sessionId)
      localStorage.setItem('reserveData', JSON.stringify(reserveData))
      localStorage.setItem('reserveUser', JSON.stringify(reserveUser))
      localStorage.setItem('settingData', JSON.stringify(settingData))
      window.location.href = sessionId.data.url
      return true
    },

    // ==========================
    // Stores 用決済処理
    async creditPaymentStores(reserveData: any, reserveUser: any, settingData: any): Promise<boolean> {
      console.log('creditPayment (Stores)', reserveUser)
      const paymentData = {
        amount: reserveUser.selectedPlan.price,
        currency: "jpy",
        locale: "ja_JP",
        successUrl: `${window.location.origin}${reserveUser.uid}/reserve?stores=stores`,
        cancelUrl: `${window.location.origin}${reserveUser.uid}`,
        method: "creditcard",
        subject: "スタンダードプラン",
        description: "ウェブサイトからの支払い",
        remarks: "お支払い期日を過ぎますと自動的にキャンセルとなります。あらかじめご了承ください。",
        uid: reserveUser.uid,
      }
      console.log('Stores paymentData:', paymentData)
      const paymentUrl: any = await cloudFunctions.storesPayment(paymentData)
      console.log('Stores paymentUrl:', paymentUrl)
      settingData.stores.id = paymentUrl.data.id
      localStorage.setItem('reserveData', JSON.stringify(reserveData))
      localStorage.setItem('reserveUser', JSON.stringify(reserveUser))
      localStorage.setItem('settingData', JSON.stringify(settingData))
      window.location.href = paymentUrl.data.links.paymentUrl
      return true
    },

    // ==========================
    // 決済処理のラッパー（Stripe/Stores の設定に応じて呼び分け）
    async creditPayment(reserveData: any, reserveUser: any, settingData: any): Promise<boolean> {
      if (!settingData.stripe.use && !settingData.stores.use) {
        return true
      } else if (settingData.stripe.use && !settingData.stores.use) {
        return await this.creditPaymentStripe(reserveData, reserveUser, settingData)
      } else if (!settingData.stripe.use && settingData.stores.use) {
        return await this.creditPaymentStores(reserveData, reserveUser, settingData)
      }
      // 両方利用可能な場合は Stripe を優先する例
      return await this.creditPaymentStripe(reserveData, reserveUser, settingData)
    },

    // ==========================
    // Zoom ミーティング作成処理
    async createZoomMeeting(reserveData: any, reserveUser: any): Promise<any> {
      console.log('createZoomMeeting', reserveData)
      try {
        const payload = {
          topic: reserveData.title,
          type: "2",
          start_time: reserveData.start,
          timezone: "Asia/Tokyo",
          settings: { use_pmi: "false" },
          uid: reserveUser.uid,
        }
        const zoomUrl = await cloudFunctions.getZoomIDV2(payload)
        return zoomUrl.data
      } catch (e) {
        console.error('createZoomMeeting error:', e)
        throw new Error('Zoomミーティングの作成に失敗しました')
      }
    },

    // ==========================
    // 正式予約登録処理
    async addReserve(zoomResult: any, reserveData: any,reserveUser:any): Promise<void> {
      console.log('addReserve', reserveData)
      // ここでは、Firestore の予約保存処理を呼び出す（例：firebase.firestore.save.reserve.reserveSave()）
      await firestoreService.save.reserve.reserveSave(reserveData.id || '')

      //GoogleカレンダーのgoogleCalendarEventIdがあれば、Googleカレンダーの該当イベントの内容変更
      const eventId = reserveData.extendedProps.googleCalendarEventId;
      if (eventId) {
        const payload = {
          eventId,
          eventData: reserveUser,
          calendarId: reserveData.extendedProps.calendarId,
          meetingUrl: zoomResult
        }
        await cloudFunctions.googleCalendarEventUpdate(payload)
      }
    },

    // ==========================
    // メール送信処理
    async sendEmail(zoomResult: any, reserveData: any, settingData: any, reserveUser: any): Promise<void> {
      console.log('sendEmail', zoomResult, reserveData)
      const reserveDate = dayjs(reserveData.start).format('YYYY年M月D日HH時mm分') + '～' +
                          dayjs(reserveData.end).format('HH時mm分')
      const paymentDate = dayjs(reserveData.start).format('YYYY年M月D日')
      reserveUser.reserveDate = reserveDate
      let msg: any = {}
      if (zoomResult) {
        const zoomUrlLabel = zoomResult.split('?')
        msg = {
          testFlag: false,
          templateId: 'd-957a2dab508e4d6fbf2bdc0de8d1266b',
          from: "reservesystem@infom.jp",
          to: reserveUser.email,
          replyTo: settingData.replyMailAddress,
          dynamic_template_data: {
            clientName: reserveUser.name,
            subject: reserveData.plan
              ? `${reserveUser.selectedPlan.title}の予約を受け付けました`
              : `${reserveUser.selectedPlan.title}の支払いが完了しました`,
            item: reserveUser.plans,
            mainTextBefore: settingData.mainTextBefore,
            mainTextAfter: settingData.mainTextAfter,
            signature: settingData.signature,
            reserveDate,
            to: reserveUser.email,
            replyTo: settingData.replyMailAddress,
            courceName: reserveUser.selectedPlan.title,
            zoomUrl: (typeof zoomResult === 'string') ? zoomResult : '予約無し決済のみ',
            zoomUrlLabel: (typeof zoomResult === 'string') ? zoomUrlLabel[0] : "",
          }
        }
      } else if (!zoomResult && !reserveData.paymentOnly) {
        msg = {
          testFlag: false,
          templateId: 'd-f9d74dd16ba24094b05003f48d1640a7',
          from: "reservesystem@infom.jp",
          to: reserveUser.email,
          replyTo: settingData.replyMailAddress,
          dynamic_template_data: {
            clientName: reserveUser.name,
            subject: `${reserveData.title}の予約が完了しました`,
            item: reserveUser.plans,
            courceName: reserveUser.selectedPlan.title,
            mainTextBefore: settingData.mainTextBefore,
            mainTextAfter: settingData.mainTextAfter,
            signature: settingData.signature,
            reserveDate,
            to: reserveUser.email,
            replyTo: settingData.replyMailAddress,
          }
        }
      } else if (reserveData.paymentOnly) {
        msg = {
          testFlag: false,
          templateId: 'd-b1e267b2f4aa4fd3845d6740c990995d',
          from: "reservesystem@infom.jp",
          to: settingData.replyMailAddress,
          replyTo: reserveUser.email,
          dynamic_template_data: {
            clientName: settingData.name,
            subject: `${reserveUser.selectedPlan.title}の支払いが完了しました`,
            item: reserveUser.plans,
            courceName: reserveUser.selectedPlan.title,
            mainTextBefore: settingData.mainTextBefore,
            mainTextAfter: settingData.mainTextAfter,
            signature: settingData.signature,
            paymentDate,
            reserveDate,
            to: reserveUser.email,
            replyTo: settingData.replyMailAddress,
            name: reserveUser.name,
            email: reserveUser.email,
          }
        }
      }
      console.log('sendEmail message:', msg)
      // 実際は EmailService.sendMailTemplate(msg) を呼び出す
      await EmailService.sendMailTemplate(msg)
    },

    // ==========================
    // 予約完了処理
    async reserveComplete(reserveData: any, settingData: any, reserveUser: any): Promise<void> {
      console.log('reserveComplete', reserveData)
      const reserveDate = dayjs(reserveData.start).format('YYYY年M月D日HH時mm分') + '～' +
                          dayjs(reserveData.end).format('HH時mm分')
      let msg: any = {}
      if (!reserveData.paymentOnly) {
        msg = {
          testFlag: false,
          templateId: 'd-f0804f3e6bfb40a991d0a404b2bd5648',
          from: "reservesystem@infom.jp",
          replyTo: settingData.replyMailAddress,
          to: settingData.replyMailAddress,
          dynamic_template_data: {
            clientName: settingData.name,
            name: reserveUser.name,
            email: reserveUser.email,
            subject: `${reserveUser.selectedPlan.title}の新規予約を受け付けました`,
            // item: reserveUser.plans,
            reserveDate: reserveDate,
            replyTo: settingData.replyMailAddress,
            to: settingData.replyMailAddress,
            courceName: reserveUser.selectedPlan.title,
          }
        }
      } else {
        msg = {
          testFlag: false,
          templateId: 'd-df50c2427e7a4026912f95c951ff7814',
          from: "reservesystem@infom.jp",
          replyTo: settingData.replyMailAddress,
          to: reserveUser.email,
          dynamic_template_data: {
            clientName: settingData.name,
            name: reserveUser.name,
            email: reserveUser.email,
            subject: `${reserveUser.selectedPlan.title}の支払いが完了しました`,
            item: reserveUser.plans,
            reserveDate: reserveUser.reserveDate,
            replyTo: settingData.replyMailAddress,
            to: settingData.replyMailAddress,
            signature: settingData.signature,
            courceName: reserveUser.selectedPlan.title,
          }
        }
      }
      console.log('reserveComplete message:', msg)
      await EmailService.sendMailTemplate(msg)
    },

    async eject(router: any, reserveData: any, error: any = '') {
      await this.ejectReserveTemp(reserveData.id)
      localStorage.removeItem('reserveData')
      localStorage.removeItem('reserveUser')
      localStorage.removeItem('settingData')
      router.back()
      // ここで calendarStore.showModal を false にする必要がある場合は別途処理する
      console.log('予約エラー', error)
    },

    async ejectReserveTemp(reserveDateId: string) {
      if (reserveDateId) {
        await firestoreService.save.reserve.tempReturn(reserveDateId)
      }
      // 必要ならカレンダーの再読込処理
      // await method.calendar.eventLoad()
      console.log('ejectReserveTemp 完了')
    },
  }
})
