<template>
  <v-tabs v-model="tab" align-tabs="center">
    <v-tab value="1">予約一覧</v-tab>
    <v-tab value="2">支払いのみ一覧</v-tab>
  </v-tabs>
  <v-tabs-window v-model="tab">
    <!-- 予約一覧 -->
    <v-tabs-window-item value="1">
      <v-data-table
        :items="calendarReserver"
        :headers="reservedPage.reserveHeaders"
        v-model:sort-by="reservedPage.sortBy"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>予約一覧</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-dialog v-model="reservedPage.show" width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="reservedPage.toggleHelp">
                    <v-icon>mdi-help-circle-outline</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    予約が完了した人の一覧です。予約編集の欄の
                    <v-icon small>mdi-pencil</v-icon> で各種編集やZOOM URLの確認、
                    <v-icon small>mdi-delete</v-icon> で予約のキャンセルが出来ます。ただし料金の返金は出来ませんので、別途ご対応をお願いします。
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-toolbar-items>
            <!-- 編集ダイアログ -->
            <v-dialog v-model="reservedPage.dialog" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="reservedPage.editedItem.name" label="お名前"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="reservedPage.editedItem.email" label="メールアドレス"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="reservedPage.editedItem.title" label="プラン名(変更不可)" readonly></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="reservedPage.editedItem.orderDate" label="予約日(変更不可)" readonly></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="reservedPage.editedItem.zoomUrl" label="ZOOM URL(変更不可)" readonly></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="reservedPage.editedItem.paymentId" label="支払いID(変更不可)" readonly></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" @click="reservedPage.close">キャンセル</v-btn>
                  <v-btn color="blue darken-1" @click="reservedPage.save">保存する</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- キャンセル確認ダイアログ -->
            <v-dialog v-model="reservedPage.dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">この予約をキャンセルしてよろしいですか?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" @click="reservedPage.deleteItemConfirm">はい</v-btn>
                  <v-btn color="blue darken-1" @click="reservedPage.closeDelete">いいえ</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="reservedPage.editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="reservedPage.deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-tabs-window-item>
    <!-- 支払いのみ一覧 -->
    <v-tabs-window-item value="2">
      <v-data-table
        :items="paymentOnlyReserver"
        :headers="reservedPage.paymentOnlyHeaders"
        v-model:sort-by="reservedPage.sortBy"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>支払いのみ一覧</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-dialog v-model="reservedPage.showPaymentOnly" width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="reservedPage.togglePaymentOnlyHelp">
                    <v-icon>mdi-help-circle-outline</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    日時やZOOMの予約のない、支払いのみの人の一覧です。顧客情報編集の欄の
                    <v-icon small>mdi-pencil</v-icon> で顧客の詳細を確認出来ます。
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-toolbar-items>
            <!-- 支払いのみ編集ダイアログ -->
            <v-dialog v-model="reservedPage.dialogPaymentOnly" max-width="500px">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="reservedPage.editedItem.name" label="お名前"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="reservedPage.editedItem.email" label="メールアドレス"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="reservedPage.editedItem.title" label="プラン名(変更不可)" readonly></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="reservedPage.editedItem.orderDate" label="支払い日(変更不可)" readonly></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="reservedPage.editedItem.paymentId" label="支払いID(変更不可)" readonly></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" @click="reservedPage.close">キャンセル</v-btn>
                  <v-btn color="blue darken-1" @click="reservedPage.save">保存する</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- 支払いのみ削除確認ダイアログ -->
            <v-dialog v-model="reservedPage.dialogPaymentOnlyDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">
                  この支払いを削除してよろしいですか?<br>※この処理は戻せません
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" @click="reservedPage.deleteItemConfirmPaymentOnly">はい</v-btn>
                  <v-btn color="blue darken-1" @click="reservedPage.closeDelete">いいえ</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="reservedPage.editItem(item)">
            mdi-pencil
          </v-icon>
          <!-- 削除アイコンはコメントアウトされているので不要な場合は削除 -->
        </template>
      </v-data-table>
    </v-tabs-window-item>
  </v-tabs-window>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useReservedPageStore } from '@/stores/reservedPageStore';

const tab = ref(1);
const reservedPage = useReservedPageStore();

// serverItems や sortBy、ヘッダーなどは reservedPage 内に状態として持つ前提
const calendarReserver = computed(() =>
  reservedPage.serverItems.filter((item: any) => item.paymentOnly === false)
);
const paymentOnlyReserver = computed(() =>
  reservedPage.serverItems.filter((item: any) => item.paymentOnly === true)
);
</script>
