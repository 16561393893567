import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue'
import { useUserStore } from '@/stores/userStore'
import { useAuthStore } from '@/stores/authStore'
import { useDashboardCalendarStore } from '@/stores/dashboardCalendarStore'

const baseUrl = 'https://reservev2.infom.jp';


export default /*@__PURE__*/_defineComponent({
  __name: 'Dashboard',
  setup(__props) {

const userStore = useUserStore();
const authStore = useAuthStore();
const dashboardCalendarStore = useDashboardCalendarStore();

const url = computed(() => {
  return `${baseUrl}/${authStore.uid}/`;
});

const shippingUrl = computed(() => {
  return `${baseUrl}/shipping/${authStore.uid}/`;
});

const selectedUrl = computed(() => {
  let urlTemp = `${baseUrl}/${authStore.uid}/?`;
  if (userStore.selectedUse.length > 0) {
    urlTemp = userStore.selectedUse.reduce((acc, use, index) => {
      const paramKey = `plan${index}`;
      return acc + `${paramKey}=${use}`;
    }, urlTemp);
  }
  return urlTemp;
});

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!

  return (_openBlock(), _createBlock(_component_v_container, { "fill-height": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        "align-content": "center",
        justify: "center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { align: "center" }, {
            default: _withCtx(() => [
              (!_unref(authStore).loggedIn)
                ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_container, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, { justify: "center" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, {
                                    align: "right",
                                    "align-self": "center",
                                    xs: "12",
                                    lg: "3"
                                  }, {
                                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                                      _createTextVNode(" メールアドレス ")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    align: "left",
                                    xs: "12",
                                    lg: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        modelValue: _unref(authStore).email,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(authStore).email) = $event))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _withDirectives(_createVNode(_component_v_row, { justify: "center" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, {
                                    align: "right",
                                    "align-self": "center",
                                    xs: "12",
                                    lg: "3"
                                  }, {
                                    default: _withCtx(() => _cache[17] || (_cache[17] = [
                                      _createTextVNode(" パスワード ")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, {
                                    align: "left",
                                    xs: "12",
                                    lg: "4"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        modelValue: _unref(authStore).password,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(authStore).password) = $event)),
                                        type: "password"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 512), [
                                [_vShow, !_unref(authStore).resetPassword]
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_actions, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_spacer),
                          _withDirectives(_createVNode(_component_v_btn, {
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(authStore).login(_unref(authStore).email, _unref(authStore).password))),
                            disabled: _unref(authStore).loginButton
                          }, {
                            default: _withCtx(() => _cache[18] || (_cache[18] = [
                              _createTextVNode(" ログインする ")
                            ])),
                            _: 1
                          }, 8, ["disabled"]), [
                            [_vShow, !_unref(authStore).resetPassword]
                          ]),
                          _withDirectives(_createVNode(_component_v_btn, {
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(authStore).resetPasswordAction())),
                            disabled: !_unref(authStore).email.trim()
                          }, {
                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                              _createTextVNode(" リセットメールを送信する ")
                            ])),
                            _: 1
                          }, 8, ["disabled"]), [
                            [_vShow, _unref(authStore).resetPassword]
                          ]),
                          _withDirectives(_createVNode(_component_v_btn, {
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(authStore).resetPassword = !_unref(authStore).resetPassword))
                          }, {
                            default: _withCtx(() => _cache[20] || (_cache[20] = [
                              _createTextVNode(" パスワードを忘れた場合 ")
                            ])),
                            _: 1
                          }, 512), [
                            [_vShow, !_unref(authStore).resetPassword]
                          ]),
                          _withDirectives(_createVNode(_component_v_btn, {
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(authStore).resetPassword = !_unref(authStore).resetPassword))
                          }, {
                            default: _withCtx(() => _cache[21] || (_cache[21] = [
                              _createTextVNode(" ログイン画面に戻る ")
                            ])),
                            _: 1
                          }, 512), [
                            [_vShow, _unref(authStore).resetPassword]
                          ]),
                          _createVNode(_component_v_spacer)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_v_card, { key: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => _cache[22] || (_cache[22] = [
                          _createTextVNode(" ログインしました。 ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => _cache[23] || (_cache[23] = [
                          _createTextVNode("カレンダーページURL")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            lg: "6",
                            xs: "12",
                            readonly: true,
                            "append-inner-icon": "mdi-content-copy",
                            modelValue: url.value,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((url).value = $event)),
                            onClick: _cache[8] || (_cache[8] = ($event: any) => (_unref(dashboardCalendarStore).copyUrl(1, url.value)))
                          }, {
                            default: _withCtx(() => [
                              _cache[25] || (_cache[25] = _createTextVNode(" 設置URL ")),
                              _createVNode(_component_v_tooltip, {
                                activator: "parent",
                                "open-on-hover": false,
                                location: "end",
                                modelValue: _unref(dashboardCalendarStore).tooltip1,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(dashboardCalendarStore).tooltip1) = $event))
                              }, {
                                default: _withCtx(() => _cache[24] || (_cache[24] = [
                                  _createTextVNode(" コピーしました ")
                                ])),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => _cache[26] || (_cache[26] = [
                          _createTextVNode(" プラン購入専用ページURL(カレンダーからの時間予約無しで、決済のみ可能なページのURLです。) ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            lg: "6",
                            xs: "12",
                            readonly: true,
                            "append-inner-icon": "mdi-content-copy",
                            modelValue: shippingUrl.value,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((shippingUrl).value = $event)),
                            onClick: _cache[11] || (_cache[11] = ($event: any) => (_unref(dashboardCalendarStore).copyUrl(3, shippingUrl.value)))
                          }, {
                            default: _withCtx(() => [
                              _cache[28] || (_cache[28] = _createTextVNode(" 設置URL ")),
                              _createVNode(_component_v_tooltip, {
                                activator: "parent",
                                "open-on-hover": false,
                                location: "end",
                                modelValue: _unref(dashboardCalendarStore).tooltip3,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(dashboardCalendarStore).tooltip3) = $event))
                              }, {
                                default: _withCtx(() => _cache[27] || (_cache[27] = [
                                  _createTextVNode(" コピーしました ")
                                ])),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => _cache[29] || (_cache[29] = [
                          _createTextVNode(" 条件付きURL(使用したいプランを選択してください。下記URLはチェックの入っているプランのみ、カレンダーに表示されます。) ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(userStore).reservePlans, (plan) => {
                            return (_openBlock(), _createBlock(_component_v_checkbox, {
                              lg: "6",
                              xs: "12",
                              key: plan.id,
                              label: plan.title,
                              value: plan.id,
                              modelValue: _unref(userStore).selectedUse,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(userStore).selectedUse) = $event))
                            }, null, 8, ["label", "value", "modelValue"]))
                          }), 128)),
                          _createVNode(_component_v_text_field, {
                            lg: "6",
                            xs: "12",
                            readonly: true,
                            "append-inner-icon": "mdi-content-copy",
                            modelValue: selectedUrl.value,
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((selectedUrl).value = $event)),
                            onClick: _cache[15] || (_cache[15] = ($event: any) => (_unref(dashboardCalendarStore).copyUrl(2, selectedUrl.value)))
                          }, {
                            default: _withCtx(() => [
                              _cache[31] || (_cache[31] = _createTextVNode(" 設置URL ")),
                              _createVNode(_component_v_tooltip, {
                                activator: "parent",
                                "open-on-hover": false,
                                location: "end",
                                modelValue: _unref(dashboardCalendarStore).tooltip2,
                                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(dashboardCalendarStore).tooltip2) = $event))
                              }, {
                                default: _withCtx(() => _cache[30] || (_cache[30] = [
                                  _createTextVNode(" コピーしました ")
                                ])),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})