// src/stores/reserveStore.ts
import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import type { ReserveUser, SettingData } from '@/types/ReservationTypes';
import { TempReservationService } from '@/services/TempReservationService';
import { PaymentService, PaymentData } from '@/services/PaymentService';
import { ZoomService } from '@/services/ZoomService';
import { OrderService } from '@/services/OrderService';
import { EmailService } from '@/services/EmailService';
import { ErrorHandler } from '@/services/ErrorHandler';

export const useReserveStore = defineStore('reserve', {
  state: () => ({
    reserveData: {} as any, // 予約対象のイベントデータ。必要に応じて型定義を作成してください
    reserveUser: {} as ReserveUser,
    settingData: {} as SettingData,
  }),
  actions: {
    loadFromLocalStorage() {
      const temp = localStorage.getItem('reserveData');
      const tempUser = localStorage.getItem('reserveUser');
      const tempSetting = localStorage.getItem('settingData');
      if (temp && tempUser && tempSetting) {
        this.reserveData = JSON.parse(temp);
        this.reserveUser = JSON.parse(tempUser);
        this.settingData = JSON.parse(tempSetting);
      }
    },

    // 予約プロセス全体を統合するエントリーポイント
    async submitReservation(router: any) {
      try {
        // 仮予約登録
        const reserveData = Array.isArray(this.reserveData) ? this.reserveData[0] : this.reserveData;
        if (!reserveData || !reserveData.id) throw new Error("Invalid reserveData");

        const tempResult = await TempReservationService.addTempReservation(reserveData.id);
        if (!tempResult) throw new Error("Temp reservation failed");

        // 必要に応じて、予約ページに遷移
        router.push('./reserve');

        // 予約確認処理へ
        await this.confirmReservation(router, reserveData);
      } catch (e) {
        await ErrorHandler.handleReservationError(
          router,
          this.reserveData,
          e,
          TempReservationService.cancelTempReservation
        );
      }
    },

    async confirmReservation(router: any, reserveData: any) {
      try {
        // Zoomミーティングの作成が必要な場合
        let zoomResult: any = null;
        if (this.settingData.zoom && !reserveData.paymentOnly) {
          const payload = {
            topic: reserveData.title,
            type: "2",
            start_time: reserveData.start,
            timezone: "Asia/Tokyo",
            settings: { use_pmi: "false" },
            uid: this.reserveUser.uid,
          };
          zoomResult = await ZoomService.createMeeting(payload);
          if (!zoomResult) throw new Error("Zoom meeting creation failed");
        }

        // 決済処理が必要な場合
        if (
          (this.settingData.stripe.use || this.settingData.stores.use) &&
          reserveData.price &&
          reserveData.price !== 0
        ) {
          if (this.settingData.stripe.use && !this.settingData.stores.use) {
            if (!this.settingData.stripe.connectedAccountId) {
              throw new Error("Stripe account not set");
            }
            const paymentData: PaymentData = {
              successUrl: `${window.location.origin}/${this.reserveUser.uid!}/reserve?session_id={CHECKOUT_SESSION_ID}`,
              cancelUrl: `${window.location.origin}/${this.reserveUser.uid!}`,
              lineItems: [{
                price_data: {
                  currency: 'jpy',
                  product_data: { name: this.reserveUser.selectedPlan.title },
                  unit_amount: this.reserveUser.selectedPlan.price ?? 0,
                },
                quantity: 1,
              }],
              email: this.reserveUser.email,
              amount: this.reserveUser.selectedPlan.price ?? 0,
              paymentMethodId: ['card'],
              mode: 'payment',
              customerId: this.reserveUser.uid!,
              description: '予約料金',
              receiptEmail: this.reserveUser.email,
              metadata: { reserveId: this.reserveUser.reserveDate },
              test: this.settingData.stripe.test,
              connectedAccountId: this.settingData.stripe.connectedAccountId,
              uid: this.reserveUser.uid!,
            };
            
            const sessionId: any = await PaymentService.processStripePayment(paymentData);
            // 予約前の状態を保存してリダイレクト
            localStorage.setItem('reserveData', JSON.stringify(this.reserveData));
            localStorage.setItem('reserveUser', JSON.stringify(this.reserveUser));
            localStorage.setItem('settingData', JSON.stringify(this.settingData));
            window.location.href = sessionId.data.url;
            return;
          } else if (!this.settingData.stripe.use && this.settingData.stores.use) {
            const paymentData: PaymentData = {
              successUrl: `${window.location.origin}/${this.reserveUser.uid!}/reserve?session_id={CHECKOUT_SESSION_ID}`,
              cancelUrl: `${window.location.origin}/${this.reserveUser.uid!}`,
              lineItems: [{
                price_data: {
                  currency: 'jpy',
                  product_data: { name: this.reserveUser.selectedPlan.title },
                  unit_amount: this.reserveUser.selectedPlan.price ?? 0,
                },
                quantity: 1,
              }],
              email: this.reserveUser.email,
              amount: this.reserveUser.selectedPlan.price ?? 0,
              paymentMethodId: ['card'],
              mode: 'payment',
              customerId: this.reserveUser.uid!,
              description: '予約料金',
              receiptEmail: this.reserveUser.email,
              metadata: { reserveId: this.reserveUser.reserveDate },
              test: this.settingData.stripe.test,
              connectedAccountId: this.settingData.stripe.connectedAccountId ?? '',
              uid: this.reserveUser.uid!,
            };
            
            const paymentUrl: any = await PaymentService.processStoresPayment(paymentData);
            this.settingData.stores.id = paymentUrl.data.id;
            localStorage.setItem('reserveData', JSON.stringify(this.reserveData));
            localStorage.setItem('reserveUser', JSON.stringify(this.reserveUser));
            localStorage.setItem('settingData', JSON.stringify(this.settingData));
            window.location.href = paymentUrl.data.links.paymentUrl;
            return;
          }
        }

        // 注文情報オブジェクトの構築
        const order = {
          reserveId: reserveData.id,
          paymentId: reserveData.paymentId || '',
          name: this.reserveUser.name || '',
          email: this.reserveUser.email || '',
          title: reserveData.title || '',
          uid: this.reserveUser.uid || '',
          zoomUrl: zoomResult || '',
          start: reserveData.start || '',
          end: reserveData.end || '',
          orderDate: this.reserveUser.reserveDate || dayjs().format('YYYY年M月D日'),
          paymentOnly: reserveData.paymentOnly || false,
        };
        await OrderService.saveOrder(order);

        // メール送信
        const emailData = {
          to: this.reserveUser.email,
          subject: `${this.reserveUser.selectedPlan.title}の予約が完了しました`,
          // 必要な情報を追加
        };
        await EmailService.sendConfirmationEmail(emailData);

        // 予約完了処理（必要に応じて追加処理）
        await this.finalizeReservation(reserveData);

        router.push('./ReserveComplete');
      } catch (e: any) {
        await ErrorHandler.handleReservationError(
          router,
          reserveData,
          e,
          TempReservationService.cancelTempReservation
        );
      }
    },

    async finalizeReservation(reserveData: any) {
      console.log("Finalizing reservation", reserveData);
      // 予約完了後の内部処理（例：内部通知、ログ出力など）
    },

    async cancelReservation(router: any, reserveData: any, error: any = '') {
      await TempReservationService.cancelTempReservation(reserveData.id);
      localStorage.removeItem('reserveData');
      localStorage.removeItem('reserveUser');
      localStorage.removeItem('settingData');
      router.back();
      console.error("Reservation cancelled due to error:", error);
    },
    async submitShipping(router: any, selectedPlan: any) {
      // shipping 用の決済処理開始
      selectedPlan.paymentOnly = true
      this.reserveUser.submit = true

      try {
        // shipping ページの場合、stripe は使わず stores 決済を利用する前提
        if (!this.settingData.stripe.use && this.settingData.stores.use) {
          const paymentData: PaymentData = {
            successUrl: `${window.location.origin}/${this.reserveUser.uid!}/reserve?stores=stores`,
            cancelUrl: `${window.location.origin}/${this.reserveUser.uid!}`,
            lineItems: [{
              price_data: {
                currency: 'jpy',
                product_data: { name: selectedPlan.title },
                unit_amount: selectedPlan.price ?? 0,
              },
              quantity: 1,
            }],
            email: this.reserveUser.email,
            amount: selectedPlan.price ?? 0,
            paymentMethodId: ['card'],
            mode: 'payment',
            customerId: this.reserveUser.uid!,
            description: '予約料金',
            receiptEmail: this.reserveUser.email,
            metadata: { reserveId: this.reserveUser.reserveDate },
            test: this.settingData.stripe.test,
            connectedAccountId: this.settingData.stripe.connectedAccountId ?? '',
            uid: this.reserveUser.uid!,
          }

          const paymentUrl: any = await PaymentService.processStoresPayment(paymentData)
          // 更新があれば保存
          this.settingData.stores.id = paymentUrl.data.id
          localStorage.setItem('reserveData', JSON.stringify(this.reserveData))
          localStorage.setItem('reserveUser', JSON.stringify(this.reserveUser))
          localStorage.setItem('settingData', JSON.stringify(this.settingData))
          // 決済ページへリダイレクト
          window.location.href = paymentUrl.data.links.paymentUrl
          return
        } else {
          throw new Error("Unsupported payment configuration for shipping")
        }
      } catch (e: any) {
        await ErrorHandler.handleReservationError(
          router,
          selectedPlan, // ※ shipping では仮予約登録が不要な場合もあるため、エラー処理には selectedPlan を渡しています
          e,
          TempReservationService.cancelTempReservation
        )
      }
    },
  },
});
