// src/stores/dashboardCalendarStore.ts
import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import { firestoreService } from '@/services/firebaseService';
import { useAuthStore } from './authStore';
import { useUserStore } from './userStore';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'
import { cloudFunctions } from '@/services/cloudFunctions';
import { useSettingsStore } from '@/stores/settingsStore';

export interface MenuItem {
  text: string;
  action: string;
  date?: any;
}

export interface DashboardCalendarState {
  options: any;
  showModal: boolean;
  addReserveModal: boolean;
  adding: boolean;
  selectedEventDetails: any[];
  events: any[];
  menu: boolean;
  menuX: number;
  menuY: number;
  menuItems: MenuItem[];
  tooltip1: boolean;
  tooltip2: boolean;
  tooltip3: boolean;
  event: {
    copyData: any[];
  };
  locale: string;
  plugins: any[];
  calendarId: string;
  googleCalendarEventsFetched: boolean;
}

export const useDashboardCalendarStore = defineStore('dashboardCalendar', {
  state: (): DashboardCalendarState => ({
    options: {},
    showModal: false,
    addReserveModal: false,
    adding: false,
    selectedEventDetails: [],
    events: [],
    menu: false,
    menuX: 0,
    menuY: 0,
    menuItems: [],
    tooltip1: false,
    tooltip2: false,
    tooltip3: false,
    event: {
      copyData: [],
    },
    locale: "ja-JP",
    plugins: [dayGridPlugin,interactionPlugin],
    calendarId: '',
    googleCalendarEventsFetched: false,
  }),
  actions: {
    exchangeTimes(event: any) {
      console.log('exchangeTimes', event);
      const start = new Date(event.start);
      // event.end が null または未定義の場合は start を使用する
      const end = event.end ? new Date(event.end) : start;
      const startHour = start.getHours().toString().padStart(2, '0');
      const startMinute = start.getMinutes().toString().padStart(2, '0');
      const endHour = end.getHours().toString().padStart(2, '0');
      const endMinute = end.getMinutes().toString().padStart(2, '0');
      const month = start.getMonth() + 1;
      const day = start.getDate();
      const eventDay = start;
      return { startHour, startMinute, endHour, endMinute, month, day, eventDay };
    },
    

    exchangeTimesReverse(this: DashboardCalendarState, events: any[] = this.selectedEventDetails, date: any = null) {
        console.log('exchangeTimesReverse', events, date);
        const exchanges = events.map((event: any) => {
          let { startHour, startMinute, endHour, endMinute, month, day } = event;
          if (date) {
            month = dayjs(date).month() + 1;
            day = dayjs(date).date();
          }
          console.log('exchangeTimesReverse', startHour, startMinute, endHour, endMinute, month, day);
          const eventDay = dayjs().month(month - 1).date(day).format('YYYY-MM-DD');
          console.log('eventDay', eventDay);
          const currentDate = new Date(eventDay);
          const year = currentDate.getFullYear();
          const startDateTime = new Date(year, month - 1, day, Number(startHour), Number(startMinute));
          const endDateTime = new Date(year, month - 1, day, Number(endHour), Number(endMinute));
          return { startDateTime, endDateTime, month, day };
        });
        console.log('exchanges', exchanges);
        return exchanges;
      },
      

      pushSelectedEvents(events: any[]) {
        console.log('pushSelectedEvents', events);
        if (this.selectedEventDetails.length >= 1) {
          this.selectedEventDetails = [];
        }
        // userStore からユーザーの予約プランを取得
        const userStore = useUserStore();
        events.forEach((event: any) => {
          const { startHour, startMinute, endHour, endMinute, month, day, eventDay } = this.exchangeTimes(event);
          // もしイベントにプラン情報がない場合は、ユーザーの予約プランをセットする（必須なので必ずセットされる前提）
          if (!event.extendedProps?.plan || event.extendedProps.plan.length === 0) {
            console.log('plan初期値',event.extendedProps?.plan);
            event.plan = cloneDeep(userStore.reservePlans);
          } else {
            console.log('イベントのプラン');
            event.plan = event.extendedProps.plan;
          }
          // selectedPlan はそのまま extendedProps から取得（必須のため、fallback しない）
          const selectedPlan = event.extendedProps ? event.extendedProps.selectedPlan : event.selectedPlan;
          console.log('event.extendedProps?.googleCalendarEventId', event.extendedProps?.googleCalendarEventId);
          this.selectedEventDetails.push({
            month,
            day,
            title: event.title || '',
            start: event.start,
            end: event.end,
            startStr: '',
            startHour,
            startMinute,
            endHour,
            endMinute,
            description: event.extendedProps?.description || '',
            plan: event.plan,
            id: event.id,
            eventDay,
            selectedPlan, // ここはそのまま
            googleCalendarEventId: event.extendedProps?.googleCalendarEventId || '',
            googleCalendar: event.extendedProps?.googleCalendar || false,
          });
        });
        console.log('this.selectedEventDetails', this.selectedEventDetails);
      },
      

    eventClick({ event }: any) {
      console.log('eventClick', event);
      const events = [event];
      if (this.selectedEventDetails.length >= 1) {
        this.selectedEventDetails = [];
      }
      this.showModal = true;
      this.pushSelectedEvents(events);
    },

    dateClick({ dateStr }: any) {
      console.log('dateClick', dateStr);
      if (this.selectedEventDetails.length >= 1) {
        this.selectedEventDetails = [];
      }
      const parts = dateStr.split('-');
      const month = parts[1];
      const day = parts[2];
      this.addReserveModal = true;
    
      // userStore から予約プラン一覧を取得してコピー
      const userStore = useUserStore();
      const defaultPlans = cloneDeep(userStore.reservePlans);
    
      // 既存の当日のイベント情報を allCopyEvent() でコピー
      this.allCopyEvent();
      
      // allCopyEvent() により this.event.copyData に当日のイベントがコピーされている前提で、
      // そのコピーデータから各イベントの時間帯（開始・終了）を抽出
      const bookedSlots = this.event.copyData.map((event: any) => {
        // event.start, event.end は Date 形式の文字列で入っている前提
        const start = new Date(event.start);
        const end = new Date(event.end);
        return {
          startHour: start.getHours(),
          startMinute: start.getMinutes(),
          endHour: end.getHours(),
          endMinute: end.getMinutes(),
        };
      });
    
      // 新規追加用の selectedEventDetails に、既存の予約済み時間帯情報 (bookedSlots) を含めて追加
      this.selectedEventDetails.push({
        month,
        day,
        title: '',
        description: '',
        plan: defaultPlans,
        startHour: 0,
        startMinute: 0,
        endHour: 0,
        endMinute: 0,
        eventId: '',
        eventDay: dateStr,
        start: '',
        end: '',
        startStr: '',
        selectedPlan: {},
        // ここで既存の予約時間帯を付与
        invalidTimeSlots: bookedSlots,
      });
    },    

    eventDidMount(info: any) {
      console.log('eventDidMount', info);
      info.el.addEventListener('contextmenu', (e: any) => {
        e.stopPropagation();
        const events = [info.event];
        this.pushSelectedEvents(events);
        this.menuItems = [
          { text: 'コピー', action: 'copy' },
          { text: '削除', action: 'delete' }
        ];
        e.preventDefault();
        const scrollX = window.pageXOffset || document.documentElement.scrollLeft;
        const scrollY = window.pageYOffset || document.documentElement.scrollTop;
        const menuX = e.clientX + scrollX;
        const menuY = e.clientY + scrollY;
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;
        const menuHeight = 100;
        const menuWidth = 150;
        let adjustedMenuY = menuY;
        let adjustedMenuX = menuX;
        if (menuY + menuHeight > windowHeight + scrollY) {
          adjustedMenuY = windowHeight + scrollY - menuHeight;
        }
        if (menuX + menuWidth > windowWidth + scrollX) {
          adjustedMenuX = windowWidth + scrollX - menuWidth;
        }
        this.menuX = adjustedMenuX;
        this.menuY = adjustedMenuY;
        this.menu = true;
        console.log('eventDidMount1', this);
      });
    },

    dayCellDidMount(info: any) {
      info.el.addEventListener('contextmenu', (e: any) => {
        console.log('dayCellDidMount', info);
        e.stopPropagation();
        if (info.date) {
          const eventDate = dayjs(info.date).format('YYYY-MM-DD');
          const events = this.events.filter((event: any) => {
            return dayjs(event.start).format('YYYY-MM-DD') === eventDate;
          });
          console.log('events', events, eventDate);
          this.pushSelectedEvents(events);
        }
        this.menuItems = [
          { text: '全てコピー', action: 'allCopy' },
          { text: '貼り付け', action: 'paste', date: info.date },
        ];
        e.preventDefault();
        this.menuX = e.pageX;
        this.menuY = e.pageY;
        this.menu = true;
      });
    },

    menuAction(action: any, date: any) {
      console.log('menuAction', action, date);
      if (action === 'copy') {
        this.copyEvent();
      } else if (action === 'allCopy') {
        this.allCopyEvent();
      } else if (action === 'paste') {
        this.pasteEvent(date);
      } else if (action === 'delete') {
        this.deleteEvent();
      }
      this.menu = false;
    },

    copyEvent() {
      console.log('copy', this.selectedEventDetails);
      if (this.event.copyData.length >= 1) {
        this.event.copyData = [];
      }
      // shallow copyではなく、deep clone を使ってコピー
      this.event.copyData.push(...this.selectedEventDetails.map((ev: any) => cloneDeep(ev)));
      console.log('copyData', this.event.copyData);
    },
    
    allCopyEvent() {
      if (this.event.copyData.length >= 1) {
        this.event.copyData = [];
      }
      // こちらも deep clone でコピー
      this.event.copyData.push(...this.selectedEventDetails.map((ev: any) => cloneDeep(ev)));
      console.log('copyData', this.event.copyData);
    },

    pasteEvent(date: any) {
      console.log('paste', date);
      this.saveEventNew(this.event.copyData, date);
    },

    deleteEvent() {
      const targetEventId = this.selectedEventDetails[0]?.id;
      const googleCalendarEventId = this.selectedEventDetails[0]?.googleCalendarEventId ? this.selectedEventDetails[0]?.googleCalendarEventId : null;
      const confirmResult = confirm('削除しますか？');
      console.log('delete', this.selectedEventDetails,googleCalendarEventId);
      if (confirmResult && targetEventId) {
        console.log('delete', targetEventId);
        if (googleCalendarEventId){
          const SettingsStore = useSettingsStore();
          const deleteData = {
            calendarId: SettingsStore.settingData.calendarId,
            eventId: googleCalendarEventId
          }
          console.log('deleteData', deleteData);
          cloudFunctions.deleteCalendarEvent(deleteData);
        }
        // Firebase への削除呼び出し
        firestoreService.delete.event(targetEventId);
        this.events = this.events.filter((event: any) => event.id !== targetEventId);
      }
    },

    async saveEventNew(
      this: DashboardCalendarState,
      setEvent: any = this.selectedEventDetails,
      date: any = null
    ) {
      const authStore = useAuthStore();
      const currentUid = authStore.uid;
    
      for (const eventDetail of setEvent) {
        const exchangeTimes = (this as ReturnType<typeof useDashboardCalendarStore>).exchangeTimesReverse([eventDetail], date);
        const { startDateTime, endDateTime } = exchangeTimes[0];
        console.log('new save', eventDetail, startDateTime, endDateTime);
        const docRef = firestoreService.save.event.getNewDocID(); // 新規ドキュメントID取得
    
        const newEventData = {
          uid: currentUid,
          id: docRef.id,
          title: eventDetail.title || '',
          start: startDateTime,
          end: endDateTime || startDateTime,
          description: eventDetail.description || '',
          plan: eventDetail.plan || [],
          // もし selectedPlan が空のオブジェクトなら空文字列に変換
          selectedPlan: (eventDetail.selectedPlan && Object.keys(eventDetail.selectedPlan).length > 0)
                          ? eventDetail.selectedPlan
                          : '',
          orderId: '',
          name: '',
          email: '',
          calendarId: '',
          googleCalendarEventId: '',
        };
    
        console.log('newEventData before cleaning:', newEventData);
        const SettingsStore = useSettingsStore();
        console.log('SettingsStore.settingData.calendarId', SettingsStore.settingData.calendarId);
        //Googleカレンダーへの書き込み。返り値としてeventIdを受け取る。
        if(SettingsStore.settingData.calendarId){
          const payload = {
            eventData:newEventData,
            calendarId: SettingsStore.settingData.calendarId
          };
          const response = await cloudFunctions.createCalendarEvent(payload);
          const data = response.data as { success: boolean; id: string };
          newEventData.googleCalendarEventId = data.id;
        }
        //Firestoreへ書き込み。
        await firestoreService.save.event.new(docRef, newEventData);
        this.events.push(newEventData);
      }
      this.addReserveModal = false;
      this.adding = false;
      this.selectedEventDetails = [];
    },
    
      
      
    saveEventEdit() {
      for (const event of this.selectedEventDetails) {
        console.log('save event edit', event);
        const exchangeTimes = this.exchangeTimesReverse([event]);
        const { startDateTime, endDateTime, month, day } = exchangeTimes[0];
        event.start = startDateTime;
        event.end = endDateTime;
        event.month = month;
        event.day = day;
        // ユーザーの予約プランからタイトルを更新する処理（必要に応じて実装）
        const foundPlan: any[] = []; // ここで明示的に any[] と注釈する // ※外部ストア等からプラン情報を取得して置き換え
        const eventTitle = foundPlan && foundPlan.length > 0 ? foundPlan[0].title : "タイトル無し";
        event.title = eventTitle;
        event.selectedPlan = event.selectedPlan;
        this.events = this.events.map((editEvent: any) => {
          if (editEvent.id === event.id) {
            return { ...editEvent, title: eventTitle, start: event.start, end: event.end, description: event.description, plan: event.plan };
          }
          return editEvent;
        });
        // Firebase への更新
        firestoreService.save.event.edit(event);
      }
      console.log('edit', this.selectedEventDetails);
      this.showModal = false;
    },

    async copyUrl(tooltip: number, url: string) {
      await navigator.clipboard.writeText(url);
      if (tooltip === 1) {
        this.tooltip1 = true;
        setTimeout(() => { this.tooltip1 = false; }, 3000);
      } else if (tooltip === 2) {
        this.tooltip2 = true;
        setTimeout(() => { this.tooltip2 = false; }, 3000);
      } else if (tooltip === 3) {
        this.tooltip3 = true;
        setTimeout(() => { this.tooltip3 = false; }, 3000);
      }
    },

    //Googleカレンダーのイベントを取り込む
    async getGoogleCalendarEvents() {
      const SettingsStore = useSettingsStore();
      const calendarId = SettingsStore.settingData.readOnlyCalendarId;
      const response = await cloudFunctions.getGoogleCalendarEvents({ calendarId });
      const data = response.data as any[];
      console.log('getGoogleCalendarEvents', data,);
      if (data) {
        //既存のthis.eventsと結合
        const googleEvents = data.map((event:any) => this.convertGoogleEventToFullCalendar(event));
        console.log('googleEvents', googleEvents);
        this.events = [...this.events, googleEvents].flat();
        console.log('this.events', this.events);
      }
      this.googleCalendarEventsFetched = true;
    },

    convertGoogleEventToFullCalendar(googleEvent:any) {
      // start, end は dateTime もしくは date が存在するため、適宜取得します
      const startValue = googleEvent.start.dateTime || googleEvent.start.date;
      const endValue = googleEvent.end.dateTime || googleEvent.end.date;

      const date = {startValue, endValue};
    
      // Date オブジェクトに変換して時間情報を取得
      const { startHour, startMinute, endHour, endMinute, month, day, eventDay } = this.exchangeTimes(date);
      console.log('convertGoogleEventToFullCalendar', date,startValue, endValue, startHour, startMinute, endHour, endMinute);
      return {
        id: googleEvent.id,
        title: googleEvent.summary,
        start: startValue,
        end: endValue,
        allDay: Boolean(googleEvent.start.date),
        // 時間情報（数値）を追加
        startHour,
        startMinute,
        endHour,
        endMinute,
        backgroundColor: '#FF5733',
        borderColor: '#FF5733',
        googleCalendar:true,
      };
    }
    
  },
});
