// src/stores/userStore.ts
import { defineStore } from 'pinia';
import type { ReservePlan } from '@/types/CalendarEvent';
import { authService, firestoreService } from '@/services/firebaseService';

export interface UserState {
  uid: string;
  name: string;
  email: string;
  password: string;
  reservePlans: ReservePlan[];
  selectedUse: string[];
  resetPassword: boolean;
  check: boolean;      // ログイン済みなら true
  loginButton: boolean;
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    uid: '',
    name: '',
    email: '',
    password: '',
    reservePlans: [],
    selectedUse: [],
    resetPassword: false,
    check: false,
    loginButton: false,
  }),
  actions: {
    setUser(data: Partial<UserState>) {
      Object.assign(this, data);
    },
    async loadReservePlans(uid: string) {
      const plans = await firestoreService.fetchPlans(uid);
      this.reservePlans = plans.map((plan: any) => ({
        id: plan.id,
        title: plan.title || '',
        price: plan.price || 0,
        description: plan.description || '',
        color: plan.color,
        shipping: plan.shipping,
        use: plan.use,
        paymentId: plan.paymentId ? plan.paymentId : '',
        paymentOnly: plan.paymentOnly ? plan.paymentOnly : false,
        delete: false,
      }));
    },
    async login(email: string, password: string) {
      try {
        this.loginButton = true;
        const res = await authService.login(email, password);
        // displayName が存在すればそれを使い、なければ email を名前として設定
        const name = res.user.displayName || email;
        this.setUser({
          uid: res.user.uid,
          email,
          // password, // ※セキュリティ上、パスワードを保持するかはご検討ください
          name,
          check: true,
        });
        await this.loadReservePlans(res.user.uid);
        this.loginButton = false;
      } catch (error) {
        console.error('login error', error);
        this.loginButton = false;
        alert('ログインに失敗しました');
      }
    },
    
    async resetPasswordAction() {
      try {
        await authService.resetPassword(this.email);
        alert('パスワードリセットメールを送信しました');
      } catch (error) {
        console.error('resetPassword error', error);
        alert('パスワードリセットに失敗しました');
      }
    },
    // プランをまとめて保存するアクション
    async savePlans() {
      for (const plan of this.reservePlans) {
        // ここでは各プランを firestoreService.savePlan 経由で保存
        await firestoreService.savePlan(plan);
      }
    },
  },
});
