// src/services/OrderService.ts
import { firestoreService } from '@/services/firebaseService';

export const OrderService = {
  async saveOrder(order: any): Promise<void> {
    try {
      await firestoreService.saveOrder(order);
    } catch (e) {
      console.error("OrderService.saveOrder", e);
      throw e;
    }
  },
};
