<template>
  <v-container fill-height>
    <v-row align-content="center" justify="center">
      <v-col align="center">
        <!-- ログイン前 -->
        <v-card v-if="!authStore.loggedIn">
          <v-card-text>
            <v-container>
              <v-row justify="center">
                <v-col align="right" align-self="center" xs="12" lg="3">
                  メールアドレス
                </v-col>
                <v-col align="left" xs="12" lg="4">
                  <v-text-field v-model="authStore.email"></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" v-show="!authStore.resetPassword">
                <v-col align="right" align-self="center" xs="12" lg="3">
                  パスワード
                </v-col>
                <v-col align="left" xs="12" lg="4">
                  <v-text-field v-model="authStore.password" type="password"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-show="!authStore.resetPassword"
              @click="authStore.login(authStore.email, authStore.password)"
              :disabled="authStore.loginButton"
            >
              ログインする
            </v-btn>
            <v-btn
              v-show="authStore.resetPassword"
              @click="authStore.resetPasswordAction()"
              :disabled="!authStore.email.trim()"
            >
              リセットメールを送信する
            </v-btn>
            <v-btn
              @click="authStore.resetPassword = !authStore.resetPassword"
              v-show="!authStore.resetPassword"
            >
              パスワードを忘れた場合
            </v-btn>
            <v-btn
              @click="authStore.resetPassword = !authStore.resetPassword"
              v-show="authStore.resetPassword"
            >
              ログイン画面に戻る
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
        <!-- ログイン後 -->
        <v-card v-else>
          <v-card-text>
            ログインしました。
          </v-card-text>
          <v-card-title>カレンダーページURL</v-card-title>
          <v-card-text>
            <v-text-field
              lg="6"
              xs="12"
              :readonly="true"
              append-inner-icon="mdi-content-copy"
              v-model="url"
              @click="dashboardCalendarStore.copyUrl(1, url)"
            >
              設置URL
              <v-tooltip
                activator="parent"
                :open-on-hover="false"
                location="end"
                v-model="dashboardCalendarStore.tooltip1"
              >
                コピーしました
              </v-tooltip>
            </v-text-field>
          </v-card-text>
          <v-card-title>
            プラン購入専用ページURL(カレンダーからの時間予約無しで、決済のみ可能なページのURLです。)
          </v-card-title>
          <v-card-text>
            <v-text-field
              lg="6"
              xs="12"
              :readonly="true"
              append-inner-icon="mdi-content-copy"
              v-model="shippingUrl"
              @click="dashboardCalendarStore.copyUrl(3, shippingUrl)"
            >
              設置URL
              <v-tooltip
                activator="parent"
                :open-on-hover="false"
                location="end"
                v-model="dashboardCalendarStore.tooltip3"
              >
                コピーしました
              </v-tooltip>
            </v-text-field>
          </v-card-text>
          <v-card-title>
            条件付きURL(使用したいプランを選択してください。下記URLはチェックの入っているプランのみ、カレンダーに表示されます。)
          </v-card-title>
          <v-card-text>
            <v-checkbox
              lg="6"
              xs="12"
              v-for="plan in userStore.reservePlans"
              :key="plan.id"
              :label="plan.title"
              :value="plan.id"
              v-model="userStore.selectedUse"
            ></v-checkbox>
            <v-text-field
              lg="6"
              xs="12"
              :readonly="true"
              append-inner-icon="mdi-content-copy"
              v-model="selectedUrl"
              @click="dashboardCalendarStore.copyUrl(2, selectedUrl)"
            >
              設置URL
              <v-tooltip
                activator="parent"
                :open-on-hover="false"
                location="end"
                v-model="dashboardCalendarStore.tooltip2"
              >
                コピーしました
              </v-tooltip>
            </v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useUserStore } from '@/stores/userStore'
import { useAuthStore } from '@/stores/authStore'
import { useDashboardCalendarStore } from '@/stores/dashboardCalendarStore'

const userStore = useUserStore();
const authStore = useAuthStore();
const dashboardCalendarStore = useDashboardCalendarStore();

const baseUrl = 'https://reservev2.infom.jp';

const url = computed(() => {
  return `${baseUrl}/${authStore.uid}/`;
});

const shippingUrl = computed(() => {
  return `${baseUrl}/shipping/${authStore.uid}/`;
});

const selectedUrl = computed(() => {
  let urlTemp = `${baseUrl}/${authStore.uid}/?`;
  if (userStore.selectedUse.length > 0) {
    urlTemp = userStore.selectedUse.reduce((acc, use, index) => {
      const paramKey = `plan${index}`;
      return acc + `${paramKey}=${use}`;
    }, urlTemp);
  }
  return urlTemp;
});
</script>
